import { useState } from 'react'
import { IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonGrid, IonRow, IonCol, IonModal, IonToggle, IonContent } from '@ionic/react'
import { colors } from '../theme/colors'
import StandardContainer from '../commonComponents/StandardContainer'
import CommonHeader from '../commonComponents/CommonHeader'
import axios from 'axios'
import { fontSizes, fontWeights } from '../theme/typography'

export default function DetailsView({ loading, service, getService, showToastMessage, serviceTemp, subscriptionPackages }) {
  const [editSection, setEditSection] = useState(null) // Tracks which modal to show
  const [editedValues, setEditedValues] = useState({}) // Local state for modal edits

  const updateService = async (data) => {
    try {
      await axios.patch(`/service?id=${serviceTemp?._id}`, data)
      await getService()
      showToastMessage('Service updated successfully!', 'success')
    } catch (error) {
      showToastMessage('Failed to update service.', 'danger')
    }
  }

  const handleSave = () => {
    updateService({ ...service, ...editedValues })
    setEditSection(null) // Close modal after saving
  }

  const isDataChanged = () => {
    return (
      editedValues.isPublic !== service.isPublic ||
      editedValues.name !== service.name ||
      editedValues.price !== service.price ||
      editedValues.durationMin !== service.durationMin ||
      editedValues.description !== service.description
    )
  }

  if (!service) return 'loading...'

  return (
    <>
      {/* Main Service Details */}
      <IonGrid style={{ padding: '0 20px' }}>
        <IonRow style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <IonCol
            size='auto'
            style={{
              width: 'fit-content',
              backgroundColor: colors.black10,
              padding: '5px 12px',
              borderRadius: 20,
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <IonLabel style={{ fontSize: '12px' }}>{service?.isPublic ? 'Public' : 'Private'}</IonLabel>
          </IonCol>
          <IonCol size='auto'>
            <IonLabel
              style={{ cursor: 'pointer', fontSize: '18px', color: colors.primaryBlue }}
              onClick={() => {
                setEditedValues({
                  isPublic: service.isPublic,
                  name: service.name,
                  price: service.price,
                  durationMin: service.durationMin,
                  description: service.description,
                })
                setEditSection('mainEdit')
              }}
            >
              Edit
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <h2>{service?.name}</h2>
        </IonRow>
        <IonRow>
          <IonLabel style={{ color: colors.black70, fontWeight: '600' }}>{service.description}</IonLabel>
        </IonRow>
        <IonRow>
          <h2>${parseFloat(service?.price)?.toFixed(2)}</h2>
        </IonRow>
        <IonRow>
          <IonLabel style={{ fontSize: fontSizes.size14, fontWeight: fontWeights.weightBold }}>({service?.durationMin} mins)</IonLabel>
        </IonRow>
      </IonGrid>

      {/* Modal for Main Edit */}
      <IonModal isOpen={editSection === 'mainEdit'} onDidDismiss={() => setEditSection(null)}>
        <IonContent>
          <CommonHeader
            backIcon={true}
            saveButton={true}
            handleSaveButton={handleSave}
            saveDisabled={!isDataChanged()}
            closeButton={true}
            closeModal={() => setEditSection(null)}
          />
          <StandardContainer>
            <IonGrid style={{ padding: 20 }}>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel>Status</IonLabel>
                    <IonToggle
                      mode='ios'
                      checked={editedValues.isPublic}
                      onIonChange={(e) => setEditedValues({ ...editedValues, isPublic: e.detail.checked })}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position='stacked'>Name</IonLabel>
                    <IonInput
                      value={editedValues.name}
                      onIonInput={(e) => setEditedValues({ ...editedValues, name: e.detail.value })}
                      placeholder='Enter service name'
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position='stacked'>Price</IonLabel>
                    <IonInput
                      value={editedValues.price}
                      onIonInput={(e) => setEditedValues({ ...editedValues, price: e.detail.value })}
                      type='number'
                      placeholder='Enter price'
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position='stacked'>Duration</IonLabel>
                    <IonSelect value={editedValues.durationMin} onIonChange={(e) => setEditedValues({ ...editedValues, durationMin: e.detail.value })}>
                      {Array.from({ length: 20 }, (_, index) => {
                        const value = (index + 1) * 5
                        return (
                          <IonSelectOption key={value} value={value}>
                            {value} min
                          </IonSelectOption>
                        )
                      })}
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <IonLabel position='stacked'>Description</IonLabel>
                    <IonInput
                      value={editedValues.description}
                      onIonInput={(e) => setEditedValues({ ...editedValues, description: e.detail.value })}
                      placeholder='Enter service description'
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}
