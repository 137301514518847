import { IonGrid, IonLabel, IonRow, IonTextarea } from '@ionic/react'
import { fontSizes, fontWeights } from '../../theme/typography'
import commonStyles from '../../theme/commonstyles.module.css'
export default function StepDescription({ name, description, setDescription }) {
  return (
    <IonGrid>
      <IonRow>
        <IonLabel
          style={{
            fontSize: fontSizes.size28,
            fontWeight: fontWeights.weight600,
            lineHeight: '33.6px',
            wordWrap: 'break-word',
            marginBottom: fontSizes.size16,
          }}
        >
          Share some basic info about what {name} includes
        </IonLabel>
      </IonRow>

      <IonRow>
        <IonTextarea
          rows={5}
          onIonInput={(e) => setDescription(e.detail.value)}
          style={{ width: '100%' }}
          placeholder='Regular haircut, wash and a hot lather straight razor neck shave. Plus, a beard trim.'
          className={commonStyles.roundedInputStyle}
          value={description}
        ></IonTextarea>
      </IonRow>
    </IonGrid>
  )
}
