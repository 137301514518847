import { IonLabel, IonSpinner } from '@ionic/react'
import ConversationPlaceholder from './ConversationPlaceholder'
import { timeSince } from '../utils'
import { useEffect, useContext, useState } from 'react'
import axios from 'axios'
import { AuthContext } from '../auth'

export default function Conversation({ messages, loadingConversation, placeholderCount }) {
  const { userData } = useContext(AuthContext)
  const [animatedMessages, setAnimatedMessages] = useState([])
  const customerUnreadMessages = messages ? messages.filter((item) => item.status === 'unread' && item.authorType === 'Customer') : []
  const userUnreadMessages = messages ? messages.filter((item) => item.status === 'unread' && item.authorType === 'User') : []
  // const readMessages = messages ? messages.filter((item, index) => item.status == 'read') : []
  const readMessages = messages

  const updateMessageStatus = async (messageId) => {
    try {
      const response = await axios.patch(`/conversation/message?id=${messageId}`, {
        status: 'read',
      })

      return response.data // Return the response data if needed
    } catch (error) {
      console.error(error)
      throw new Error('Failed to update message status')
    }
  }

  useEffect(() => {
    // Update status for all unread messages when component mounts
    if (customerUnreadMessages && customerUnreadMessages.length > 0) {
      customerUnreadMessages.forEach(async (message) => {
        await updateMessageStatus(message._id) // Assuming 'id' is the unique identifier of the message
      })
    }
  }, [customerUnreadMessages]) // Trigger this effect whenever customerUnreadMessages array changes

  useEffect(() => {
    // Compare new messages with existing ones and mark new ones for animation
    const newMessages = messages.map(message => {
      const isNew = !animatedMessages.find(m => m.sid === message.sid)
      return {
        ...message,
        isNew: isNew
      }
    })
    setAnimatedMessages(newMessages)

    // Remove isNew flag after animation
    const timer = setTimeout(() => {
      setAnimatedMessages(prev => prev.map(message => ({
        ...message,
        isNew: false
      })))
    }, 300)

    return () => clearTimeout(timer)
  }, [messages])

  const hasUnreadCustomerMessages = customerUnreadMessages.some((message) => message.status === 'unread' && message.authorType === 'Customer')

  if (loadingConversation) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <IonSpinner name='dots' />
      </div>
    )
  }

  const formatTime = (dateString) => {
    if (!dateString) return ''
    const date = new Date(dateString)
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  }

  const formatDayHeader = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', { 
      weekday: 'short', 
      month: 'short', 
      day: 'numeric' 
    })
  }

  const groupMessagesByDate = (messages) => {
    const groups = {}
    messages.forEach(message => {
      const date = new Date(message.date_created)
      const dayKey = date.toDateString()
      if (!groups[dayKey]) {
        groups[dayKey] = []
      }
      groups[dayKey].push(message)
    })
    return groups
  }

  const isUserMessage = (author) => {
    // Check if the author is not a phone number (i.e., it's a user ID)
    return !author.startsWith('+')
  }

  const renderMessages = () => {
    const groupedMessages = groupMessagesByDate([...animatedMessages].reverse())
    return Object.entries(groupedMessages).map(([date, messagesForDate], groupIndex) => (
      <div key={date} className="message-group">
        <div className="date-separator">
          <div className="date-text">{formatDayHeader(date)}</div>
        </div>
        {messagesForDate.map((message) => (
          <div
            key={message.sid}
            className={`message ${isUserMessage(message.author) ? 'sent' : 'received'} ${message.isNew ? 'new-message' : ''}`}
          >
            {message.body}
            <div className="message-time">
              {formatTime(message.date_created)}
            </div>
          </div>
        ))}
      </div>
    ))
  }

  return (
    <div className="message-wrap">
      {renderMessages()}
    </div>
  )
}
