import { IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonModal, IonPicker, IonPickerColumn, IonPickerColumnOption, IonRow } from '@ionic/react'
import { fontSizes } from '../../theme/typography'
import commonStyles from '../../theme/commonstyles.module.css'
import { useState } from 'react'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/StandardContainer'
import { chevronDown } from 'ionicons/icons'
export default function PreviewDuration({ durationMin, setDurationMin, selectedTime, setSelectedTime }) {
  const [showModal, setShowModal] = useState(false)
  const [dataChanged, setDataChanged] = useState(false)
  const [tempDurationMin, setTempDurationMin] = useState(durationMin)
  const [tempSelectedTime, setTempSelectedTime] = useState({ ...selectedTime })

  const handleSaveButton = () => {
    setSelectedTime(tempSelectedTime)
    setDurationMin(tempDurationMin) // Store the combined value in state
    setDataChanged(false)
    setShowModal(false)
  }
  const handleTempTime = (type, value) => {
    setDataChanged(true)
    // Use tempSelectedTime for updates
    const updatedTime = { ...tempSelectedTime, [type]: value }
    const hoursInMinutes = parseInt(updatedTime.hour, 10) * 60 // Convert hours to minutes
    const minutes = parseInt(updatedTime.minute, 10) // Convert minutes string to int

    const totalDuration = hoursInMinutes + minutes

    setTempDurationMin(totalDuration) // Update temporary duration
    setTempSelectedTime(updatedTime) // Update temporary selected time
  }

  const closeModal = () => {
    setTempDurationMin(durationMin)
    setTempSelectedTime({ ...selectedTime })
    setShowModal(false)
    setDataChanged(false)
  }

  // Generate Hours: 0 - 10
  const hours = Array.from({ length: 11 }, (_, i) => ({ text: i.toString(), value: i }))

  // Generate Minutes: 00 - 55 (Interval of 5)
  const minutes = Array.from({ length: 12 }, (_, i) => ({
    text: (i * 5).toString().padStart(2, '0'),
    value: (i * 5).toString().padStart(2, '0'),
  }))
  return (
    <>
      <IonRow style={{ width: '100%', marginTop: 10 }}>
        <IonItem onClick={() => setShowModal(true)} className={commonStyles.inputRoundedStyleNoPadding} style={{ cursor: 'pointer', width: '100%' }}>
          <IonGrid style={{ padding: 0, marginLeft: -5 }}>
            <IonRow>
              <IonCol>
                <IonRow>
                  <IonLabel style={{ fontSize: fontSizes.size11, marginBottom: 5 }}>Duration</IonLabel>
                </IonRow>
                <IonRow>
                  <IonLabel style={{ fontSize: fontSizes.size12 }}>{durationMin} min</IonLabel>
                </IonRow>
              </IonCol>
              <IonCol style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} size='auto'>
                <IonIcon icon={chevronDown} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonRow>

      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <IonContent>
          <CommonHeader
            title={'Choose Duration'}
            closeButton={true}
            closeModal={closeModal}
            saveDisabled={!dataChanged}
            saveButton={true}
            handleSaveButton={handleSaveButton}
          />
          <StandardContainer>
            <IonRow>
              <IonCol
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <IonLabel
                  style={{
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '24px',
                  }}
                >
                  Hours
                </IonLabel>
                <IonPicker>
                  <IonPickerColumn onIonChange={(e) => handleTempTime('hour', e.target.value)} value={tempSelectedTime.hour}>
                    {hours.map((item) => (
                      <IonPickerColumnOption key={item.value} value={item.value}>
                        {item.text}
                      </IonPickerColumnOption>
                    ))}
                  </IonPickerColumn>
                </IonPicker>
              </IonCol>
              <IonCol
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <IonLabel
                  style={{
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '24px',
                  }}
                >
                  Minutes
                </IonLabel>
                <IonPicker>
                  <IonPickerColumn onIonChange={(e) => handleTempTime('minute', e.target.value)} value={tempSelectedTime.minute}>
                    {minutes.map((item) => (
                      <IonPickerColumnOption key={item.value} value={item.value}>
                        {item.text}
                      </IonPickerColumnOption>
                    ))}
                  </IonPickerColumn>
                </IonPicker>
              </IonCol>
            </IonRow>
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}
