import { IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonReorder, IonRow, IonToggle, IonButton, IonText } from '@ionic/react'
import React, { useState } from 'react'
import { calculateCostPerMonth, getPricing } from '../utils'
import { pencilOutline } from 'ionicons/icons'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { colors } from '../theme/colors'
import MigrationModal from './MigrationModal'

export default function SubscriptionOptionItem({
  pricingList,
  subscriptionPackage_id,
  handleUpdateOption,
  reorderEnabled,
  addOption,
  handlePriceToggle,
  handleNameChange,
  activeSubscriptionCounts,
  subscriptionPlanName,
  refreshSubscriptionCounts
}) {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [optionToEdit, setOptionToEdit] = useState(false)
  const [dataChanged, setDataChanged] = useState(false)
  const [name, setName] = useState(false)
  const [priceEnabled, setPriceEnabled] = useState(false)
  const [migrationModalOpen, setMigrationModalOpen] = useState(false)
  const openEditModal = (option) => {
    setOptionToEdit(option)
    setName(option.name)
    setPriceEnabled(option.active)
    setEditModalOpen(true)
  }
  const closeEditModal = () => {
    setEditModalOpen(false)
    setOptionToEdit(false)
    setDataChanged(false)
    setName(false)
    setPriceEnabled(false)
  }
  const changeName = (value, option) => {
    setDataChanged(true)
    setName(value)
  }
  const changePrice = (value, option) => {
    setDataChanged(true)
    setPriceEnabled(value)
  }
  const saveUpdatedData = () => {
    handleNameChange(name, optionToEdit)
    handlePriceToggle(priceEnabled, optionToEdit)
    closeEditModal()
  }
  return (
    <>
      {pricingList &&
        pricingList.map((option, index) => {
          const subscriberCount = activeSubscriptionCounts?.[option.stripePriceId] || 0;
          return (
            <IonItem
              key={option.stripePriceId}
              style={{
                pading: 0,
                margin: 0,
              }}
              detail={!subscriptionPackage_id}
              onClick={() => (!subscriptionPackage_id ? handleUpdateOption(option, index) : null)}
            >
              <IonReorder style={{ width: '100%', padding: 0, margin: 0 }}>
                <IonGrid style={{ pading: 0, margin: 0, marginBottom: 10, border: '1px solid gray', borderRadius: 10 }}>
                  <IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {reorderEnabled ? (
                      <IonCol style={{ padding: 0, margin: 0 }} size='auto'>
                        <div
                          style={{
                            height: 2,
                            width: 20,
                            backgroundColor: 'grey',
                            margin: 4,
                            marginTop: 20,
                            marginLeft: 4,
                          }}
                        />
                        <div style={{ height: 2, width: 20, backgroundColor: 'grey', margin: 4 }} />
                        <div style={{ height: 2, width: 20, backgroundColor: 'grey', margin: 4 }} />
                        <div
                          style={{
                            height: 2,
                            width: 20,
                            backgroundColor: 'transparent',
                            marginLeft: 20,
                          }}
                        />
                      </IonCol>
                    ) : null}
                    <IonCol>
                      <IonRow
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          pading: 0,
                          margin: 0,
                        }}
                      >
                        <IonCol sizeXs='5' sizeMd='5'>
                          <h6 style={{ fontWeight: 'bold', color: option.active ? '' : 'grey', margin:0 }}>{option.name}</h6>
                          {subscriberCount > 0 && (
                            <p style={{ 
                              margin: '0', 
                              fontSize: '12px', 
                              color: 'grey'
                            }}>
                              {subscriberCount} active subscriber{subscriberCount !== 1 ? 's' : ''}
                            </p>
                          )}
                        </IonCol>
                        <IonCol
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                          }}
                        >
                          {option.amount && (
                            <>
                              <IonRow style={{ width: '100%'}}>
                                {option.interval_count > 1 ? (
                                  <h6
                                    style={{
                                      color: option.active ? '' : 'grey',
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      marginTop: 0,
                                    }}
                                  >
                                    {getPricing(option)}
                                  </h6>
                                ) : (
                                  <h6
                                    style={{
                                      color: option.active ? '' : 'grey',
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      marginTop: 0,
                                    }}
                                  >
                                    {Math.abs(option.amount)} every {option.interval_count}{' '}
                                    {option.interval_count > 1 ? `${option.interval}s` : option.interval}
                                  </h6>
                                )}
                              </IonRow>
                            </>
                          )}
                          {option.amount && (
                            <IonRow style={{ width: '100%' }}>
                              <h6 style={{ margin: '0', color: 'grey', fontSize: '12px' }}>
                                ${calculateCostPerMonth(option.amount, option.interval, option.interval_count)}
                                &nbsp;per month
                              </h6>
                            </IonRow>
                          )}
                        </IonCol>
                        {subscriptionPackage_id && (
                          <IonCol size='auto'>
                            <IonIcon onClick={() => openEditModal(option)} color='primary' icon={pencilOutline} style={{ marginRight: 10 }} />
                          </IonCol>
                        )}
                      </IonRow>
                      {!option.active && (
                        <IonRow style={{ backgroundColor: '#e6e6e6', margin: -10, marginTop:4, padding: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                          <IonCol>
                            <h6 style={{ color: 'gray', marginBottom: 0, marginTop: 0 }}>Inactive</h6>
                          </IonCol>
                        </IonRow>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonReorder>
            </IonItem>
          )
        })}
      <IonModal isOpen={editModalOpen}>
        <IonContent style={{ '--background': 'white' }}>
          {/* Header */}
          <div style={{
            position: 'sticky',
            top: 0,
            background: 'white',
            zIndex: 100,
            padding: '16px',
            borderBottom: '1px solid #e0e0e0',
            display: 'flex',
            alignItems: 'center',
            gap: '16px'
          }}>
            <div style={{ flex: 1 }}>
              <h1 style={{
                fontSize: '20px',
                fontWeight: '600',
                margin: 0,
                color: '#222222'
              }}>
                Edit Option
              </h1>
            </div>
            <IonButton
              fill="clear"
              disabled={!dataChanged}
              onClick={saveUpdatedData}
              style={{
                margin: 0,
                '--color': dataChanged ? '#3880ff' : '#999999'
              }}
            >
              Save
            </IonButton>
            <IonButton
              fill="clear"
              onClick={closeEditModal}
              style={{
                margin: 0,
                '--padding-start': '14px',
                '--padding-end': '14px',
                color: '#999999',
                fontSize: '24px'
              }}
            >
              ✕
            </IonButton>
          </div>

          {/* Content */}
          <div style={{ 
            padding: '24px 16px',
            maxWidth: '600px',
            margin: '0 auto'
          }}>
            <div style={{
              background: '#fff',
              borderRadius: '12px',
              boxShadow: '0 1px 2px rgba(0,0,0,0.08), 0 4px 12px rgba(0,0,0,0.05)',
              padding: '24px',
              marginBottom: '24px'
            }}>
              <div style={{ marginBottom: '24px' }}>
                <IonText color="medium" style={{ 
                  fontSize: '12px',
                  display: 'block',
                  marginBottom: '8px'
                }}>
                  Option Name
                </IonText>
                <IonInput
                  onIonInput={(e) => changeName(e.detail.value, optionToEdit)}
                  placeholder="Enter option name"
                  value={name}
                  style={{
                    '--padding-start': '16px',
                    '--padding-end': '16px',
                    '--padding-top': '12px',
                    '--padding-bottom': '12px',
                    '--background': '#f7f7f7',
                    '--border-radius': '8px',
                    '--placeholder-color': '#999999'
                  }}
                />
              </div>

              {!addOption && (
                <div style={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '16px',
                  background: '#f7f7f7',
                  borderRadius: '8px',
                  marginBottom: '24px'
                }}>
                  <IonText style={{ fontWeight: '500' }}>Status</IonText>
                  <IonToggle
                    justify="space-between"
                    size="small"
                    checked={priceEnabled ? true : false}
                    onIonChange={(e) => changePrice(e.detail.checked, optionToEdit)}
                    style={{
                      '--handle-background': '#fff',
                      '--handle-background-checked': '#fff',
                      '--track-background': '#e0e0e0',
                      '--track-background-checked': '#3880ff'
                    }}
                  />
                </div>
              )}

              {!addOption && optionToEdit && activeSubscriptionCounts?.[optionToEdit.stripePriceId] > 0 && (
                <div style={{ 
                  borderTop: '1px solid #e0e0e0',
                  marginTop: '24px',
                  paddingTop: '24px'
                }}>
                  <div 
                    onClick={() => setMigrationModalOpen(true)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      color: '#3880ff',
                      cursor: 'pointer',
                      fontSize: '14px',
                      transition: 'opacity 0.2s ease',
                      ':hover': {
                        opacity: 0.8
                      }
                    }}
                  >
                    <span style={{ fontWeight: '500' }}>
                      {activeSubscriptionCounts[optionToEdit.stripePriceId]} active subscriber{activeSubscriptionCounts[optionToEdit.stripePriceId] !== 1 ? 's' : ''}
                    </span>
                    <span style={{ color: '#666666' }}>•</span>
                    <span>Change pricing for subscribers</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </IonContent>
      </IonModal>
      <MigrationModal 
        isOpen={migrationModalOpen}
        closeModal={() => setMigrationModalOpen(false)}
        option={optionToEdit}
        subscriptionPlanName={subscriptionPlanName}
        refreshSubscriptionCounts={refreshSubscriptionCounts}
      />
    </>
  )
}
