import { IonButton, IonButtons, IonContent, IonLabel, IonModal, IonSpinner } from '@ionic/react'
import React from 'react'
import { fontSizes, fontWeights } from '../theme/typography'
import StandardContainer from './StandardContainer'

export default function CancelFeeModal({ title, message, cancel, loading, save, saveText, cancelText }) {
  return (
    <IonContent className='regularBackground'>
      <StandardContainer padding={20}>
        <div style={{ marginTop: '2rem', padding: '1rem', display: 'flex', flexDirection: 'column' }}>
          <IonLabel style={{ fontSize: fontSizes.size25, marginBottom: 20, fontWeight: fontWeights.weight600 }}>{title}</IonLabel>
          <IonLabel style={{ fontSize: fontSizes.size18, fontWeight: fontWeights.weight500 }}>{message}</IonLabel>
        </div>

        <IonButton disabled={loading} style={{ width: '100%', marginTop: 30 }} color={'dark'} onClick={save}>
          {loading && <IonSpinner name='lines' style={{ marginRight: 20 }} />}
          {!loading && <IonLabel>{saveText}</IonLabel>}
        </IonButton>
        <IonButton style={{ width: '100%', marginTop: 10 }} fill='outline' onClick={() => cancel()} color={'dark'}>
          {cancelText}
        </IonButton>
      </StandardContainer>
    </IonContent>
  )
}
