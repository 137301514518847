import { useContext, useEffect, useState } from 'react'
import { IonButton, IonLabel, IonModal, IonContent, IonToast, IonRow, IonGrid, IonCol, IonIcon, IonSpinner } from '@ionic/react'
import axios from 'axios'
import StandardContainer from '../commonComponents/StandardContainer'
import { closeOutline } from 'ionicons/icons'
import { AuthContext } from '../auth'
import { fontSizes, fontWeights } from '../theme/typography'
import { colors } from '../theme/colors'
import StepDescribeService from './Steps/StepDescribeService'
import StepName from './Steps/StepName'
import StepDescription from './Steps/StepDescription'
import StepDuration from './Steps/StepDuration'
import StepPrice from './Steps/StepPrice'
import StepMemberships from './Steps/StepMemberships'
import StepProfessionals from './Steps/StepProfessionals'
import StepFinishAndPublish from './Steps/StepFinishAndPublish'
import StepPreview from './Steps/StepPreview'
import StepSuccess from './Steps/StepSuccess'
import StepError from './Steps/StepError'
import ProcessCard from './Steps/ProcessCard'
import HeaderComponent from './Steps/headerComponent'
export default function CreateTestDialog({ open, handleClose, businessId, locationId }) {
  const [creatingService, setCreatingService] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [durationMin, setDurationMin] = useState('')
  const [price, setPrice] = useState('')
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })
  const [isIncludedInMembership, setIsIncludedInMembership] = useState(null)
  const [stepName, setStepName] = useState('') // Renamed to stepName
  const [subscriptionPackages, setSubscriptionPackages] = useState([])
  const [packagesLoading, setPackagesLoading] = useState(false)
  const { businessData } = useContext(AuthContext)
  const { selectedLocationData } = useContext(AuthContext)
  const [selectedPackages, setSelectedPackages] = useState([])
  const [selectedTime, setSelectedTime] = useState({ hour: 0, minute: '00' })
  const [selectedEmployees, setSelectedEmployees] = useState([]) // Selected employees state
  const [employees, setEmployees] = useState([])
  const [employeesLoading, setEmployeesLoading] = useState(false)
  const [statusType, setStatusType] = useState(false)
  const steps = [
    'stepDescribeService',
    'name',
    'description',
    'duration',
    'price',
    'memberships',
    'professionals',
    // 'step2Summary',
    // 'uploadImages',
    // 'previewImages',
    'finishAndPublish',
    'preview',
    'status',
  ]
  const handleSelectedTime = (type, value) => {
    const updatedTime = { ...selectedTime, [type]: value }

    let hoursInMinutes = parseInt(updatedTime.hour) * 60 // Convert hours to minutes
    let minutes = parseInt(updatedTime.minute) // Convert minutes string to int

    const totalDuration = hoursInMinutes + minutes
    setSelectedTime(updatedTime)
    setDurationMin(totalDuration) // Store the combined value in state
  }
  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }
  const republish = () => {
    createButtonClicked()
  }
  const createButtonClicked = async () => {
    setCreatingService(true)

    const locData = locationId ? { locations: [locationId] } : {}
    const input = { name, description, durationMin, businessId, price, ...locData, includedInSubscriptions: selectedPackages || [], isPublic: true }

    try {
      // Step 1: Create the service
      const serviceCreated = await createService(input)

      if (serviceCreated) {
        const newServiceId = serviceCreated._id // Get the new service ID

        // Step 2: Update employees with the new service ID if employees are selected
        const employeesUpdated = selectedEmployees && selectedEmployees.length > 0 ? await updateEmployees(selectedEmployees, newServiceId) : true

        if (employeesUpdated) {
          // Proceed to the next step on success
          setStepName('status')
          setStatusType('success')
        } else {
          // Handle case when employee update fails
          setStepName('status')
          setStatusType('error')
        }
      } else {
        // Handle case when service creation fails
        setStepName('status')
        setStatusType('error')
      }
    } catch (error) {
      console.error('Error during service creation or employee update:', error)
      setStepName('status')
      setStatusType('error')
    } finally {
      setCreatingService(false)
    }
  }

  // Function to handle service creation
  const createService = async (input) => {
    try {
      const response = await axios.post('/service', input)

      if (response.status === 200) {
        return response.data // Return the created service data
      } else {
        throw new Error('Failed to create service')
      }
    } catch (error) {
      console.error('Error creating service:', error)
      return null // Return null if creation fails
    }
  }

  // Function to handle updating selected employees
  const updateEmployees = async (selectedEmployees, newServiceId) => {
    try {
      const updateRequests = selectedEmployees
        .map((employeeId) => {
          const employeeData = employees?.find((item) => item._id === employeeId)

          if (employeeData) {
            const employeeServicesIds = employeeData?.servicesInfo?.map((item) => item._id) || []
            const updatedServices = [...employeeServicesIds, newServiceId]

            return axios.patch(`/employee?id=${employeeData._id}`, { services: updatedServices })
          }

          return null // In case employee data is not found
        })
        .filter(Boolean) // Remove null values from the request array

      if (updateRequests.length > 0) {
        const results = await Promise.all(updateRequests)
        return results.every((res) => res.status === 200) // Return true if all requests were successful
      }

      return true // No employees selected, return true
    } catch (error) {
      console.error('Error updating employees:', error)
      return false // Return false if any error occurs
    }
  }

  const close = () => {
    setName('')
    setDescription('')
    setDurationMin('')
    setSelectedTime({ hour: 0, minute: '00' })
    setSelectedPackages([])
    setIsIncludedInMembership(false)
    setPrice('')
    setCreatingService(false)
    setStepName('') // Reset stepName on close
    handleClose()
    setSelectedEmployees([])
  }
  const handlePackage = (item) => {
    setSelectedPackages((prev) => (prev.includes(item) ? prev.filter((pkg) => pkg !== item) : [...prev, item]))
  }
  const getSubscriptionPackagesList = async () => {
    if (!businessData?._id) return
    setPackagesLoading(true)
    try {
      let response = await axios.get(`/subscriptionPackage/getList?businessId=${businessData._id}`)
      setSubscriptionPackages(response.data)
      setPackagesLoading(false)
    } catch (error) {
      console.error('Failed to load subscription packages.', error) // Log the error for debugging
    }
  }
  const handleIncludeInMemberships = (isIncluded) => {
    setIsIncludedInMembership(isIncluded)
    setSelectedPackages([])
  }
  const getEmployeesList = async () => {
    try {
      setEmployeesLoading(true)
      const response = await axios.get(`/employee/getPublicList?businessId=${businessData?._id}&locationId=${locationId}`)
      setEmployees(response.data) // Update state with employees data
      setEmployeesLoading(false)
    } catch (error) {
      setEmployeesLoading(false)
      console.error('Failed to load employees list:', error) // Log the error for debugging
    } finally {
    }
  }

  useEffect(() => {
    if (stepName === 'memberships') getSubscriptionPackagesList()
    if (stepName === 'professionals') getEmployeesList()
  }, [stepName])

  const handleNext = () => {
    const currentIndex = steps.indexOf(stepName)
    if (currentIndex < steps.length - 1) setStepName(steps[currentIndex + 1])
  }

  const handleBack = () => {
    const currentIndex = steps.indexOf(stepName)
    if (currentIndex >= 0) setStepName(steps[currentIndex - 1])
  }

  let stepComponent = null
  if (stepName === 'stepDescribeService') {
    stepComponent = <StepDescribeService />
  } else if (stepName === 'name') {
    stepComponent = <StepName name={name} setName={setName} />
  } else if (stepName === 'description') {
    stepComponent = <StepDescription name={name} description={description} setDescription={setDescription} />
  } else if (stepName === 'duration') {
    stepComponent = <StepDuration name={name} selectedTime={selectedTime} handleSelectedTime={handleSelectedTime} />
  } else if (stepName === 'price') {
    stepComponent = <StepPrice price={price} setPrice={setPrice} />
  } else if (stepName === 'memberships') {
    stepComponent = (
      <StepMemberships
        selectedPackages={selectedPackages}
        subscriptionPackages={subscriptionPackages}
        name={name}
        packagesLoading={packagesLoading}
        handlePackage={handlePackage}
        handleIncludeInMemberships={handleIncludeInMemberships}
        isIncludedInMembership={isIncludedInMembership}
      />
    )
  } else if (stepName === 'professionals') {
    stepComponent = (
      <StepProfessionals
        employeesLoading={employeesLoading}
        employees={employees}
        selectedEmployees={selectedEmployees}
        setSelectedEmployees={setSelectedEmployees}
      />
    )
  } else if (stepName === 'finishAndPublish') {
    stepComponent = <StepFinishAndPublish />
  } else if (stepName === 'preview') {
    stepComponent = (
      <StepPreview
        name={name}
        setName={setName}
        price={price}
        setPrice={setPrice}
        durationMin={durationMin}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        handleSelectedTime={handleSelectedTime}
        setDurationMin={setDurationMin}
        description={description}
        setDescription={setDescription}
        selectedPackages={selectedPackages}
        subscriptionPackages={subscriptionPackages}
        handlePackage={handlePackage}
        packagesLoading={packagesLoading}
        setSelectedPackages={setSelectedPackages}
        handleIncludeInMemberships={handleIncludeInMemberships}
        setIsIncludedInMembership={setIsIncludedInMembership}
        isIncludedInMembership={isIncludedInMembership}
        employees={employees}
        selectedEmployees={selectedEmployees}
        setSelectedEmployees={setSelectedEmployees}
        employeesLoading={employeesLoading}
      />
    )
  } else if (stepName === 'status') {
    if (statusType === 'success') {
      stepComponent = <StepSuccess close={close} name={name} price={price} duration={durationMin} />
    } else {
      stepComponent = <StepError creatingService={creatingService} republish={republish} close={close} name={name} price={price} duration={durationMin} />
    }
  }

  return (
    <IonModal isOpen={open} onDidDismiss={close}>
      <IonContent>
        <StandardContainer>
          {!stepName ? (
            <IonGrid style={{ padding: '20px  20px 0  20px' }}>
              <IonRow>
                <IonCol size='auto'>
                  <IonIcon onClick={close} style={{ cursor: 'pointer', height: 24, width: 24, marginLeft: -12 }} icon={closeOutline} />
                </IonCol>
                <IonCol style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <IonLabel style={{ fontSize: fontSizes.size14, fontWeight: fontWeights.weight700 }}>{selectedLocationData?.name}</IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            <div style={{ zIndex: 10, padding: '0 20px', position: 'sticky', top: 0, backgroundColor: 'white' }}>
              {stepName !== 'preview' && stepName !== 'status' && (
                <IonGrid style={{ paddingTop: 20 }}>
                  <IonRow style={{ padding: 0 }}>
                    <IonCol onClick={close} style={{ padding: 0, display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                      <IonLabel style={{ borderRadius: 20, border: `1px solid ${colors.black50}`, padding: '5px 10px' }}>Cancel</IonLabel>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}
              {stepName !== 'preview' && (
                <HeaderComponent
                  noPadding={true}
                  title='Service Creation'
                  showBackButton={true}
                  progress={{ step: steps.indexOf(stepName) + 1, total: steps.length }}
                />
              )}
            </div>
          )}

          {/* Step content */}
          {!stepName ? (
            <div style={{ padding: 20 }}>
              <IonGrid style={{ marginBottom: fontSizes.size24 }}>
                <IonRow>
                  <IonLabel style={{ fontSize: fontSizes.size32, fontWeight: fontWeights.weight600, lineHeight: '38.4px' }}>
                    It’s easy to create a new service.
                  </IonLabel>
                </IonRow>
              </IonGrid>
              <ProcessCard
                processNum={1}
                title={'Describe the new service'}
                description={'Give some basic info like how long it takes to perform this service, set your price and specify who can offer this service.'}
                imageSrc={'/assets/service-step1.png'}
              />
              {/* uncomment this when we add pictures feature for service */}
              {/* <ProcessCard
                processNum={2}
                title={'Upload any photos to show examples'}
                description={'Give some basic info like a description, price and how long it takes to perform the service'}
                imageSrc={'/assets/service-step2.png'}
              /> */}
              <ProcessCard
                processNum={2}
                title={'Review and publish'}
                description={'Look everything over and make sure you’re good to go.'}
                imageSrc={'/assets/service-step3.png'}
              />
            </div>
          ) : (
            <>
              <IonGrid style={{ padding: 20 }}>{stepComponent}</IonGrid>
            </>
          )}
        </StandardContainer>
      </IonContent>

      {/* Navigation buttons */}
      {stepName !== 'status' && (
        <>
          <div
            style={{
              borderTop: `1px solid ${colors.black30}`,
              display: 'flex',
              justifyContent: 'space-between',
              padding: 15,
              alignItems: 'center',
            }}
          >
            {!stepName && (
              <IonButton fill='clear' color='dark' onClick={close}>
                Cancel
              </IonButton>
            )}
            {stepName && (
              <IonButton fill='clear' color='dark' onClick={handleBack}>
                Back
              </IonButton>
            )}
            {steps.indexOf(stepName) < steps.length - 2 ? (
              <IonButton
                disabled={
                  (stepName === 'name' && !name) ||
                  (stepName === 'duration' && durationMin <= 0) ||
                  (stepName === 'memberships' && isIncludedInMembership && selectedPackages.length === 0)
                }
                color={!stepName ? 'primary' : 'dark'}
                onClick={handleNext}
              >
                <div style={{ padding: '0 20px' }}>
                  <IonLabel>{!stepName ? 'Start' : 'Next'}</IonLabel>
                </div>
              </IonButton>
            ) : (
              <IonButton disabled={creatingService} onClick={createButtonClicked}>
                {creatingService && <IonSpinner name='dots' />}
                {!creatingService && <IonLabel>Publish</IonLabel>}
              </IonButton>
            )}
          </div>
        </>
      )}

      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </IonModal>
  )
}
