import { IonButton, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react'
import { checkmarkCircleOutline, timeOutline } from 'ionicons/icons'
import StandardCenterCenter from '../../commonComponents/StandardCenterCenter'
import { fontSizes, fontWeights } from '../../theme/typography'

export default function StepSuccess({ close, name, price, duration }) {
  return (
    <StandardCenterCenter>
      <IonGrid>
        <StandardCenterCenter>
          <IonRow style={{ marginBottom: 28 }}>
            <IonIcon style={{ height: 50, width: 50 }} icon={checkmarkCircleOutline} color='success' />
          </IonRow>
          <IonRow>
            <IonLabel style={{ fontSize: fontSizes.size32, fontWeight: fontWeights.weight600, lineHeight: fontSizes.size38, wordWrap: 'break-word' }}>
              Service Created
            </IonLabel>
          </IonRow>

          <IonRow
            style={{
              margin: '10px 0',
              textAlign: 'center',
              fontSize: fontSizes.size22,
              fontFamily: 'Inter',
              fontWeight: fontWeights.weight600,
              lineHeight: fontSizes.size32,
              wordWrap: 'break-word',
            }}
          >
            <IonLabel>{name}</IonLabel>
          </IonRow>

          <IonRow
            style={{
              marginBottom: 10,
              textAlign: 'center',
              fontSize: fontSizes.size22,
              fontWeight: fontWeights.weight500,
              lineHeight: fontSizes.size32,
              wordWrap: 'break-word',
            }}
          >
            <IonLabel>${price}</IonLabel>
          </IonRow>
          <IonRow style={{ color: '#717171', fontSize: 18, fontFamily: 'Figtree', fontWeight: '400', lineHeight: '28px', wordWrap: 'break-word' }}>
            <IonLabel style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IonIcon style={{ marginRight: 10 }} icon={timeOutline} />
              {duration} min
            </IonLabel>
          </IonRow>
          <IonButton onClick={close} style={{ marginTop: 40 }} fill='outline' color='dark'>
            Back To Services List
          </IonButton>
        </StandardCenterCenter>
      </IonGrid>
    </StandardCenterCenter>
  )
}
