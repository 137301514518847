import { IonGrid, IonInput, IonLabel, IonRow } from '@ionic/react'
import { fontSizes, fontWeights } from '../../theme/typography'
import commonStyles from '../../theme/commonstyles.module.css'
import { colors } from '../../theme/colors'

export default function StepName({ name, setName }) {
  return (
    <IonGrid>
      <IonRow>
        <IonLabel
          style={{
            fontSize: fontSizes.size28,
            fontWeight: fontWeights.weight600,
            lineHeight: '33.6px',
            wordWrap: 'break-word',
            marginBottom: fontSizes.size16,
          }}
        >
          What’s the name of this service?
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonLabel
          style={{
            marginBottom: fontSizes.size16,
            color: colors.black80,
            fontSize: fontSizes.size18,
            lineHeight: '28px',
          }}
        >
          Shorter is usually better. You can change it at any time.
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonInput
          style={{ width: '100%' }}
          placeholder='Cut and Beard Combo'
          className={commonStyles.roundedInputStyle}
          value={name}
          onIonInput={(e) => setName(e.detail.value)}
        />{' '}
      </IonRow>
    </IonGrid>
  )
}
