import { IonGrid, IonInput, IonLabel, IonRow } from '@ionic/react'
import { fontSizes, fontWeights } from '../../theme/typography'
import { colors } from '../../theme/colors'

export default function StepPrice({ price, setPrice }) {
  return (
    <IonGrid>
      <IonRow>
        <IonLabel
          style={{
            fontSize: fontSizes.size28,
            fontWeight: fontWeights.weight600,
            lineHeight: '33.6px',
            wordWrap: 'break-word',
            marginBottom: fontSizes.size16,
          }}
        >
          Now let’s set your price
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonLabel
          style={{
            marginBottom: fontSizes.size16,
            color: colors.black80,
            fontSize: fontSizes.size18,
            lineHeight: '28px',
          }}
        >
          The base price will be the starting point for all variants. You can choose to change pricing for specific variants after setting the base.{' '}
        </IonLabel>
      </IonRow>
      <IonRow
        style={{
          display: 'flex',
          justifyContent: 'center', // Center the content horizontally
          alignItems: 'center', // Center the content vertically
        }}
      >
        {/* Wrapper to align the dollar sign with the input */}
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
          {/* Dollar sign */}
          <IonLabel
            style={{
              position: 'absolute',
              left: price.length == 0 ? -25 : 0,
              top: 0,
              fontSize: fontSizes.size30,
              marginRight: '5px', // Add space between dollar sign and input
            }}
          >
            $
          </IonLabel>
          <IonInput
            maxlength={8}
            placeholder={0}
            style={{
              width: `${price.length + 2}ch`, // Width based on the number of characters
              fontSize: fontSizes.size60,
              textAlign: 'center', // Center the text inside the input
            }}
            onIonInput={(e) => setPrice(e.target.value)}
            value={price}
          />
        </div>
      </IonRow>
    </IonGrid>
  )
}
