export default function HeaderComponent({ title, handleBack, showBackButton = true, progress = null }) {
  if (progress.step == progress.total) {
    return null
  }

  if (progress && progress.step == 1) {
    showBackButton = false
  }

  return (
    <>
      <ProgressComponent progress={progress} />
    </>
  )
}

function ProgressComponent({ progress, props }) {
  if (!progress) return null

  return (
    <>
      <div
        style={{
          width: '100%',
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 8,
          paddingBottom: 8,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: 8,
          display: 'inline-flex',
        }}
      >
        {Array.from({ length: progress.total }, (_, index) => index + 1).map((item, index) => {
          return (
            <div
              key={index}
              style={{
                borderRadius: 10,
                width: `${100 / progress.total}%`,
                border: `2px ${index + 1 <= progress.step ? '#0068DE' : '#d6d6d6'} solid`,
                transition: 'border 0.5s linear', // Add transition for border
              }}
            />
          )
        })}
      </div>
    </>
  )
}
