import { IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow } from '@ionic/react'
import { flashOutline } from 'ionicons/icons'

export default function ResultsList({ subscriptions = [], locationId }) {
  return (
    <>
      <h6 style={{ color: 'grey', marginLeft: 20 }}>Subscriptions</h6>
      <IonList inset={true} mode='ios'>
        {subscriptions?.map((row) => (
          <IonItem button detail={true} routerLink={`/core/subscriptions/${row._id}`} routerDirection='forward' key={row._id}>
            <IonLabel>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <h4 style={{ fontWeight: '600', color: 'black', fontSize: 18 }}>{`${row?.customer?.firstName} ${row?.customer?.lastName}`}</h4>
                    <p>
                      <strong>{row?.subscriptionPackage?.name}</strong>
                    </p>
                    <p>
                      ${row?.price} {row?.period}
                    </p>
                    {row?.soldEmployeeId ? (
                      <p>
                        Sold by: {row?.soldEmployee?.firstName} {row?.soldEmployee?.lastName}
                      </p>
                    ) : null}
                    {row?.previousSubscriptionId ? <p><IonIcon name={flashOutline}/>Upgraded</p> : null}
                  </IonCol>
                </IonRow>
              </IonGrid>
              {/* <h4 style={{ fontWeight: '600', color: 'black', fontSize: 18 }}>{`${row?.customer?.firstName} ${row?.customer?.lastName}`}</h4>
              <p>{row?.subscriptionPackage?.name}</p> */}
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </>
  )
}
