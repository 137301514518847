import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react'
import { colors } from '../../theme/colors'
import { fontSizes, fontWeights } from '../../theme/typography'

export default function ProcessCard({ processNum, title, description, imageSrc }) {
  return (
    <IonGrid
      style={{
        padding: '16px 0',
        display: 'flex',
        borderBottom: `1px solid ${colors.black30}`,
      }}
    >
      <IonCol size='auto'>
        <IonLabel
          style={{
            fontSize: fontSizes.size20,
            fontWeight: fontWeights.weight600,
            lineHeight: fontSizes.size32,
            wordWrap: 'break-word',
          }}
        >
          {processNum}.
        </IonLabel>
      </IonCol>
      <IonCol>
        <IonRow>
          <IonLabel
            style={{
              flex: '1 1 0',
              fontSize: fontSizes.size20,
              fontWeight: fontWeights.weight600,
              lineHeight: fontSizes.size32,
              wordWrap: 'break-word',
            }}
          >
            {title}
          </IonLabel>
        </IonRow>
        <IonRow>
          <IonLabel
            style={{
              color: '#717171',
              fontSize: fontSizes.size18,
              fontWeight: fontWeights.weight400,
              lineHeight: fontSizes.size28,
              wordWrap: 'break-word',
            }}
          >
            {description}
          </IonLabel>
        </IonRow>
      </IonCol>
      <IonCol size='auto'>
        <img src={imageSrc} alt='' />
      </IonCol>
    </IonGrid>
  )
}
