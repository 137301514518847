import { IonList, IonItem, IonLabel, IonText } from '@ionic/react'
import { calculateCostPerMonth, getPricing } from '../utils'

export default function MigrationPlanSelector({
    subscriptionPackages,
    selectedPackage,
    selectedPrice,
    handleSelectedPackage,
    handleSelectedPrice
}) {
    return (
        <div>
            {subscriptionPackages?.data?.map(packageItem => (
                <div key={packageItem._id} style={{ marginBottom: '20px' }}>
                    <IonItem
                        lines="none"
                        button
                        onClick={() => handleSelectedPackage(packageItem)}
                        style={{
                            '--background': 'white',
                            borderRadius: '8px',
                            margin: '8px 0',
                            border: '1px solid #e0e0e0',
                            transition: 'all 0.2s ease',
                            boxShadow: '0 1px 2px rgba(0,0,0,0.08)',
                        }}
                    >
                        <IonLabel>
                            <h2 style={{
                                fontWeight: 'bold',
                                color: '#222222'
                            }}>{packageItem.name}</h2>
                            <p style={{ color: '#717171' }}>{packageItem.description}</p>
                        </IonLabel>
                    </IonItem>

                    {selectedPackage?._id === packageItem._id && (
                        <IonList
                            lines="none"
                            style={{
                                marginLeft: '20px',
                                marginTop: '12px',
                                background: 'transparent'
                            }}>
                            {packageItem.prices?.map(price => (
                                <IonItem
                                    key={price.stripePriceId}
                                    button
                                    onClick={() => handleSelectedPrice(price)}
                                    style={{
                                        '--background': selectedPrice?.stripePriceId === price.stripePriceId ? '#FFF8F6' : 'white',
                                        borderRadius: '8px',
                                        margin: '4px 0',
                                        border: selectedPrice?.stripePriceId === price.stripePriceId
                                            ? '2px solid #FF385C'
                                            : '1px solid #e0e0e0',
                                        transition: 'all 0.2s ease',
                                        boxShadow: selectedPrice?.stripePriceId === price.stripePriceId
                                            ? '0 4px 12px rgba(255, 56, 92, 0.1)'
                                            : '0 1px 2px rgba(0,0,0,0.08)',
                                    }}
                                >
                                    <IonLabel>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                            <h3 style={{
                                                fontWeight: '500',
                                                color: selectedPrice?.stripePriceId === price.stripePriceId ? '#FF385C' : '#222222'
                                            }}>{price.name}</h3>
                                            {selectedPrice?.stripePriceId === price.stripePriceId && (
                                                <IonText color="danger" style={{ fontSize: '14px', fontWeight: '500' }}>
                                                    Selected
                                                </IonText>
                                            )}
                                        </div>
                                        <p style={{
                                            color: selectedPrice?.stripePriceId === price.stripePriceId ? '#FF385C' : '#222222',
                                            fontWeight: '500',
                                            marginTop: '4px'
                                        }}>{getPricing(price)}</p>
                                        <IonText color="medium" style={{
                                            fontSize: '12px',
                                            display: 'block',
                                            marginTop: '2px'
                                        }}>
                                            ${calculateCostPerMonth(price.amount, price.interval, price.interval_count)} per month
                                        </IonText>
                                    </IonLabel>
                                </IonItem>
                            ))}
                        </IonList>
                    )}
                </div>
            ))}
        </div>
    )
} 