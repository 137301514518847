import React, { useState, useEffect, useContext, useRef, useCallback } from 'react'
import axios from 'axios'
import { AuthContext } from '../auth'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
  IonToast, // Import IonToast
  IonModal,
  IonRadioGroup,
  IonRadio,
  IonTextarea,
} from '@ionic/react'
import { useHistory } from 'react-router-dom'
import StandardContainer from '../commonComponents/StandardContainer'
import MiniHeader from '../commonComponents/MiniHeader'
import { closeCircleOutline, personOutline, swapHorizontalOutline } from 'ionicons/icons'
import SalesCreditModal from './salesCreditModal'
import ChangeSubscriptionModal from './changeSubscriptionModal'
import { colors } from '../theme/colors'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'
import { cancelSubscriptionOptions } from '../StaticData/StaticData'
import CommonHeader from '../commonComponents/CommonHeader'

export default function SubscriptionDetails({ match }) {
  let subscription_id = match?.params?.id
  const didMountRef = useRef(false)

  const [value, setValue] = useState(0)
  const [subscription, setSubscription] = useState()
  const [subscriptionTemp, setSubscriptionTemp] = useState()
  const [subscriptionChanged, setSubscriptionChanged] = useState(false)
  const [salesCreditModalOpen, setSalesCreditModalOpen] = useState(false)
  const [changeSubscriptionModalOpen, setChangeSubscriptionModalOpen] = useState(false)
  const [reasonModalOpen, setReasonModalOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState('')
  const [reason, setReason] = useState('')
  const [cancellingMembership, setCancellingMembership] = useState(false)
  const { businessData, locationId, userData } = useContext(AuthContext)
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)

  const history = useHistory()
  const [discounts, setDiscounts] = useState([])

  // Toast state
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }

  const getSubscription = useCallback(async () => {
    try {
      let response = await axios.get(`/subscription?id=${subscription_id}&businessId=${businessData._id}&locationId=${locationId}`)
      setSubscription(response.data)
      setSubscriptionTemp(response.data)
      await getDiscountsList(response.data.customerId)
    } catch (error) {
      console.error('Failed to load subscription details. Please try again.', error)
    }
  }, [subscription_id, businessData._id, locationId])

  const getDiscountsList = useCallback(
    async (ignoreLoading) => {
      if (!businessData?._id) return
      try {
        let locQuery = locationId ? `&locationId=${locationId}` : ''
        let response = await axios.get(`/discount/getList?businessId=${businessData?._id}${locQuery}`)
        setDiscounts(response.data)
      } catch (error) {
        console.error('Failed to load discounts. Please try again.', error) // Log the error for debugging
      }
    },
    [businessData?._id, locationId]
  )

  useEffect(() => {
    setTimeout(() => {
      getSubscription()
    }, 200)
  }, [getSubscription, subscription_id])

  useEffect(() => {
    setTimeout(() => {
      getSubscription()
    }, 200)
  }, [])

  useEffect(() => {
    if (subscription) {
      setSelectedValue(subscription.cancellationReason || '')
      setReason(subscription.writtenFeedback || '')
    }
  }, [subscription])

  const save = async () => {
    try {
      await axios.patch(`/subscription?id=${subscription_id}&businessId=${businessData._id}&locationId=${locationId}`, subscriptionTemp)
      await getSubscription()
      setSubscriptionChanged(false)
      showToastMessage('Subscription updated successfully!', 'success')
    } catch (error) {
      showToastMessage('Failed to update subscription. Please try again.', 'danger')
    }
  }

  useIonViewWillEnter(() => {
    if (didMountRef.current) {
      getSubscription()
    } else {
      didMountRef.current = true
    }
  })

  const cancel = () => {
    setSubscriptionTemp(subscription)
    setSubscriptionChanged(false)
  }

  const handleUpgradeSubscription = async () => {
    setChangeSubscriptionModalOpen(true)
  }

  const handleCancelSubscription = () => {
    history.push(`/core/cancelSubscription/${subscription?._id}`, { subscriptionTemp })
  }

  const handleUpdateCancelSubscription = async () => {
    setCancellingMembership(true)
    try {
      // If subscription is already cancelled, just update the reason
      if (subscription?.status === 'Canceled') {
        const response = await axios.patch(`/subscription?id=${subscription_id}`, {
          cancellationReason: selectedValue,
          writtenFeedback: selectedValue === 'other' ? reason : '',
          businessId: businessData._id,
          locationId: locationId,
        })
        
        if (response?.data) {
          setReasonModalOpen(false)
          await getSubscription()
          showToastMessage('Cancellation reason updated successfully!', 'success')
        }
      } else {
        // Otherwise, proceed with cancellation
        const response = await axios.patch(`/subscription/cancel?id=${subscription_id}&customerId=${subscriptionTemp.customerId}&businessId=${businessData._id}&locationId=${locationId}`, {
          cancellationReason: selectedValue,
          writtenFeedback: selectedValue === 'other' ? reason : '',
        })

        if (response?.data?.success) {
          setReasonModalOpen(false)
          await getSubscription()
          showToastMessage('Subscription cancelled successfully!', 'success')
        }
      }
    } catch (error) {
      showToastMessage(
        subscription?.status === 'Canceled' 
          ? 'Error updating cancellation reason. Please try again.' 
          : 'Error cancelling subscription. Please try again.', 
        'danger'
      )
    } finally {
      setCancellingMembership(false)
    }
  }

  const closeReasonModal = async () => {
    setSelectedValue(subscriptionTemp?.cancellationReason)
    setReason(subscriptionTemp?.writtenFeedback)
    setReasonModalOpen(false)
    setCancellingMembership(false)
    await getSubscription()
  }

  const handleChange = (event) => {
    setSelectedValue(event.detail.value)
  }

  const handleReason = (event) => {
    setReason(event.detail.value)
  }

  const openReasonModal = () => {
    setSelectedValue(subscriptionTemp?.cancellationReason || '')
    setReason(subscriptionTemp?.writtenFeedback || '')
    setReasonModalOpen(true)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>{subscriptionTemp ? 'Subscription' : 'loading...'}</IonTitle>
          {subscriptionChanged ? (
            <>
              <IonButtons slot='end'>
                <IonButton onClick={cancel}>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot='end'>
                <IonButton color='primary' onClick={save}>
                  Save
                </IonButton>
              </IonButtons>
            </>
          ) : null}
        </IonToolbar>
      </IonHeader>

      <IonContent style={{ '--background': '#f7f7f7' }}>
        {subscription ? (
          <div style={{ maxWidth: '1024px', margin: '0 auto', padding: '24px 16px' }}>
            {/* Main Content Grid */}
            <div style={{ display: 'grid', gap: '32px', gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))' }}>
              {/* Customer Info Card */}
              <div style={{
                background: 'white',
                borderRadius: '12px',
                padding: '32px',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)',
                border: '1px solid #EBEBEB'
              }}>
                <div style={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  marginBottom: '32px',
                  gap: '16px'
                }}>
                  <div style={{
                    width: '56px',
                    height: '56px',
                    borderRadius: '50%',
                    background: '#F7F7F7',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #EBEBEB'
                  }}>
                    <IonIcon 
                      icon={personOutline} 
                      style={{ 
                        fontSize: '28px',
                        color: '#717171'
                      }}
                    />
                  </div>
                  <div>
                    <h2 style={{ 
                      margin: 0,
                      fontSize: '22px',
                      fontWeight: '600',
                      color: '#222222',
                      marginBottom: -4,
                      marginTop: 10,
                      marginLeft:-2
                    }}>
                      {subscriptionTemp?.customer?.firstName} {subscriptionTemp?.customer?.lastName}
                    </h2>
                    <IonButton
                      fill="clear"
                      routerLink={`/core/customers/${subscriptionTemp?.customerId}`}
                      style={{
                        '--color': '#3880ff',
                        margin: '-4px -16px 0',
                        fontSize: '14px',
                        fontWeight: '500'
                      }}
                    >
                      View Profile →
                    </IonButton>
                  </div>
                </div>

                <div style={{ 
                  display: 'grid',
                  gap: '24px'
                }}>
                  {[
                    {
                      label: 'Subscription Plan',
                      value: subscriptionTemp?.subscriptionPackage?.name
                    },
                    {
                      label: 'Price',
                      value: `$${subscriptionTemp?.price} ${subscriptionTemp?.period}`
                    },
                    {
                      label: 'Start Date',
                      value: new Date(subscriptionTemp?.startDate).toLocaleDateString()
                    },
                    {
                      label: 'Status',
                      value: (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                            <div style={{
                              padding: '6px 12px',
                              borderRadius: '16px',
                              fontSize: '14px',
                              fontWeight: '500',
                              background: subscription?.status === 'active' ? '#E8F5E9' : 
                                         subscription?.status === 'Canceled' ? '#F5F5F5' : '#E3F2FD',
                              color: subscription?.status === 'active' ? '#2E7D32' : 
                                    subscription?.status === 'Canceled' ? '#616161' : '#1565C0',
                              display: 'inline-flex',
                              alignItems: 'center',
                              gap: '6px'
                            }}>
                              <div style={{
                                width: '8px',
                                height: '8px',
                                borderRadius: '50%',
                                background: subscription?.status === 'active' ? '#2E7D32' : 
                                           subscription?.status === 'Canceled' ? '#616161' : '#1565C0'
                              }} />
                              {subscription?.status.charAt(0).toUpperCase() + subscription?.status.slice(1)}
                            </div>
                            {subscription?.status === 'Canceled' && (
                              <div style={{ fontSize: '14px', color: '#717171' }}>
                                on {new Date(subscriptionTemp?.endDate).toLocaleDateString()}
                              </div>
                            )}
                          </div>

                          {/* Add Cancellation Reason here */}
                          {subscription?.status === 'Canceled' && (
                            <div style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'flex-start',
                              background: '#F7F7F7',
                              padding: '12px 16px',
                              borderRadius: '8px',
                              gap: '16px'
                            }}>
                              <div>
                                <div style={{ fontSize: '14px', color: '#717171', marginBottom: '4px' }}>
                                  Reason for Cancellation
                                </div>
                                <div style={{ fontSize: '14px', color: '#222222' }}>
                                  {(() => {
                                    const reasonItem = cancelSubscriptionOptions.find(
                                      (item) => item.value === subscriptionTemp?.cancellationReason
                                    )
                                    return reasonItem?.label === 'Other' ? (
                                      <>
                                        {reasonItem.label}
                                        {subscriptionTemp.writtenFeedback && (
                                          <div style={{ 
                                            color: '#717171',
                                            marginTop: '4px'
                                          }}>
                                            {subscriptionTemp.writtenFeedback}
                                          </div>
                                        )}
                                      </>
                                    ) : reasonItem?.label
                                  })()}
                                </div>
                              </div>
                              <IonButton
                                fill="clear"
                                onClick={openReasonModal}
                                style={{
                                  '--color': '#3880ff',
                                  margin: '-8px -12px -8px 0',
                                  fontSize: '14px',
                                  fontWeight: '500'
                                }}
                              >
                                Change
                              </IonButton>
                            </div>
                          )}

                          {subscription?.status === 'Upgraded' && subscriptionTemp?.upgradedToSubscriptionId && (
                            <IonButton
                              fill="clear"
                              routerLink={`/core/subscriptions/${subscriptionTemp?.upgradedToSubscriptionId}`}
                              style={{
                                '--color': '#3880ff',
                                margin: '-8px -16px',
                                fontSize: '14px',
                                fontWeight: '500'
                              }}
                            >
                              View new subscription →
                            </IonButton>
                          )}
                        </div>
                      )
                    }
                  ].map((item, index) => (
                    <div key={index}>
                      <div style={{ 
                        fontSize: '14px',
                        color: '#717171',
                        marginBottom: '6px'
                      }}>
                        {item.label}
                      </div>
                      <div style={{ 
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#222222'
                      }}>
                        {item.value}
                      </div>
                    </div>
                  ))}

                  {/* Add action buttons here for active subscriptions */}
                  {subscription?.status === 'active' && (
                    <div style={{
                      borderTop: '1px solid #EBEBEB',
                      paddingTop: '24px',
                      marginTop: '8px',
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      gap: '12px'
                    }}>
                      <IonButton
                        expand="block"
                        fill="outline"
                        onClick={handleCancelSubscription}
                        style={{
                          '--color': '#3880ff',
                          '--border-color': '#3880ff',
                          '--border-width': '1px',
                          '--border-radius': '8px',
                          '--background-hover': 'rgba(56,128,255,0.05)',
                          margin: 0,
                          height: '44px',
                          fontWeight: '500'
                        }}
                      >
                        <IonIcon 
                          icon={closeCircleOutline} 
                          slot="start"
                          style={{ marginRight: '4px' }}
                        />
                        Cancel
                      </IonButton>
                      <IonButton
                        expand="block"
                        onClick={handleUpgradeSubscription}
                        style={{
                          '--background': '#3880ff',
                          '--background-activated': '#3171e0',
                          '--border-radius': '8px',
                          '--box-shadow': 'none',
                          margin: 0,
                          height: '44px',
                          fontWeight: '500'
                        }}
                      >
                        <IonIcon 
                          icon={swapHorizontalOutline} 
                          slot="start"
                          style={{ marginRight: '4px' }}
                        />
                        Change Plan
                      </IonButton>
                    </div>
                  )}
                </div>
              </div>

              {/* Sales Info Card */}
              <div style={{
                background: 'white',
                borderRadius: '12px',
                padding: '32px',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)',
                border: '1px solid #EBEBEB'
              }}>
                <h3 style={{ 
                  margin: '0 0 32px 0',
                  fontSize: '18px',
                  fontWeight: '600',
                  color: '#222222'
                }}>
                  Sales Information
                </h3>

                <div style={{ 
                  display: 'grid',
                  gap: '24px'
                }}>
                  <div>
                    <div style={{ 
                      fontSize: '14px',
                      color: '#717171',
                      marginBottom: '6px'
                    }}>
                      Sold By
                    </div>
                    <div style={{ 
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                      <div style={{ 
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#222222'
                      }}>
                        {subscriptionTemp?.soldEmployeeId ? (
                          <>
                            <span style={{ color: '#FFB400', marginRight: '6px' }}>★</span>
                            {subscriptionTemp?.soldEmployee?.firstName} {subscriptionTemp?.soldEmployee?.lastName}
                          </>
                        ) : (
                          'Self Sale'
                        )}
                      </div>
                      {(userEntitlements?.isManager || userEntitlements?.isAdmin) && (
                        <IonButton
                          fill="clear"
                          onClick={() => setSalesCreditModalOpen(true)}
                          style={{
                            '--color': '#3880ff',
                            margin: 0,
                            fontSize: '14px',
                            fontWeight: '500'
                          }}
                        >
                          Change
                        </IonButton>
                      )}
                    </div>
                  </div>



                  {[
                    {
                      label: 'Platform',
                      value: subscriptionTemp?.initiationPlatform
                    },
                    subscriptionTemp?.cancellationType && {
                      label: 'Cancellation Type',
                      value: subscriptionTemp?.cancellationType
                    }
                  ].filter(Boolean).map((item, index) => (
                    <div key={index}>
                      <div style={{ 
                        fontSize: '14px',
                        color: '#717171',
                        marginBottom: '6px'
                      }}>
                        {item.label}
                      </div>
                      <div style={{ 
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#222222'
                      }}>
                        {item.value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ 
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}>
            <IonSpinner name="dots" style={{ '--color': '#3880ff' }} />
          </div>
        )}

        {/* Toast Notification */}
        <IonToast
          position='top'
          isOpen={toast.isOpen}
          onDidDismiss={() => setToast({ ...toast, isOpen: false })}
          message={toast.message}
          color={toast.color}
          duration={2000}
        />
        <SalesCreditModal
          isOpen={salesCreditModalOpen}
          setIsOpen={setSalesCreditModalOpen}
          locationId={locationId}
          subscriptionId={subscription?._id}
          onSuccess={() => {
            showToastMessage('Sales credit updated successfully!', 'success')
            getSubscription()
          }}
        />
        <ChangeSubscriptionModal
          alldiscounts={discounts}
          isOpen={changeSubscriptionModalOpen}
          setIsOpen={setChangeSubscriptionModalOpen}
          locationId={locationId}
          currentSubscription={subscription}
          onSuccess={() => {
            showToastMessage('Subscription changed successfully!', 'success')
            history.goBack()
          }}
        />
        <IonModal isOpen={reasonModalOpen} mode='ios'>
          <CommonHeader
            loading={cancellingMembership}
            title={'Update Cancellation Reason'}
            closeButton={true}
            saveButton={true}
            handleSaveButton={handleUpdateCancelSubscription}
            closeModal={closeReasonModal}
          />
          <IonContent className='regularBackground'>
            <div style={{
              background: 'white',
              borderRadius: '12px',
              padding: '24px',
              margin: '16px',
              boxShadow: '0 1px 2px rgba(0,0,0,0.08)'
            }}>
              <IonRadioGroup value={selectedValue} onIonChange={handleChange}>
                {cancelSubscriptionOptions.map((item, index) => (
                  <div 
                    key={index} 
                    style={{ 
                      padding: '12px 0',
                      borderBottom: index !== cancelSubscriptionOptions.length - 1 ? '1px solid #EBEBEB' : 'none'
                    }}
                  >
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '12px',
                      cursor: 'pointer'
                    }}
                    onClick={() => setSelectedValue(item.value)}
                    >
                      <IonRadio 
                        value={item.value}
                        mode='md'
                        style={{
                          '--color-checked': '#3880ff'
                        }}
                      />
                      {item.label}
                    </div>
                  </div>
                ))}
              </IonRadioGroup>

              {selectedValue === 'other' && (
                <div style={{ marginTop: '16px' }}>
                  <IonTextarea
                    value={reason}
                    onIonChange={(e) => setReason(e.detail.value)}
                    placeholder='Please explain...'
                    rows={4}
                    style={{
                      '--background': '#f7f7f7',
                      '--border-radius': '8px',
                      '--padding-start': '16px',
                      '--padding-end': '16px',
                      '--padding-top': '12px',
                      '--padding-bottom': '12px'
                    }}
                  />
                </div>
              )}
            </div>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  )
}
