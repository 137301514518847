import { IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle, IonSelectOption, IonSelect } from '@ionic/react'
import commonStyles from '../theme/commonstyles.module.css'

export default function DetailsView({ activeSubscriptionsCount, subscriptionPackage, updateSubscriptionPackage }) {
  let onValueChange = (field, value) => {
    if(field == 'monthlyMembershipAppointments' && value == null){
      updateSubscriptionPackage({ ...subscriptionPackage, [field]: value, maxTokensAllowed: null })
    }else{
      updateSubscriptionPackage({ ...subscriptionPackage, [field]: value })
    }
  }

  if (!subscriptionPackage) return 'loading...'

  console.log(subscriptionPackage.monthlyMembershipAppointments)
  return (
    <>
      <h5 style={{ margin: '1rem', fontWeight: 'bold' }}>General</h5>
      {activeSubscriptionsCount > 0 && <IonLabel style={{ margin: '1rem', color: 'grey' }}>Active Subscriptions Count : {activeSubscriptionsCount}</IonLabel>}
      <IonItem>
        <IonLabel style={{ fontWeight: 'bold', fontSize: 14 }}>Publicly Available</IonLabel>
        <IonToggle
          // position='end'
          // justify='space-between'
          size='small'
          checked={subscriptionPackage.enabled}
          onIonChange={(e) => {
            onValueChange('enabled', e.detail.checked)
          }}
        />
      </IonItem>
      <br></br>
      <IonList mode='ios' lines='none'>
        <IonItem>
          <IonLabel position='stacked' style={{ fontWeight: 'bold', fontSize: 14 }}>Name</IonLabel>
          <IonInput
            className={commonStyles.inputStyle}
            type='text'
            value={subscriptionPackage.name}
            onIonInput={(e) => {
              onValueChange('name', e.detail.value)
            }}
          ></IonInput>
        </IonItem>
        <br></br>

        <IonItem>
          <IonLabel position='stacked' style={{ fontWeight: 'bold', fontSize: 14 }}>Description</IonLabel>
          <IonTextarea
            className={commonStyles.inputStyle}
            value={subscriptionPackage.description}
            onIonChange={(e) => {
              onValueChange('description', e.detail.value)
            }}
          ></IonTextarea>
        </IonItem>
        <br></br>
        <IonItem>
          <IonLabel position='stacked' style={{ fontWeight: 'bold', fontSize: 14 }}>Percent Discount on Product (Format 0-1)</IonLabel>
          <IonInput
            type='number'
            className={commonStyles.inputStyle}
            value={subscriptionPackage.percentDiscountOnProduct}
            onIonInput={(e) => {
              onValueChange('percentDiscountOnProduct', e.detail.value)
            }}
          ></IonInput>
        </IonItem>
        <br></br>
        <IonItem>
          <IonLabel position='stacked' style={{ fontWeight: 'bold', fontSize: 14 }}>Percent Discount on Other Services (Format 0-1)</IonLabel>
          <IonInput
            className={commonStyles.inputStyle}
            type='number'
            value={subscriptionPackage.percentDiscountOnOtherServices}
            onIonInput={(e) => {
              onValueChange('percentDiscountOnOtherServices', e.detail.value)
            }}
          ></IonInput>
        </IonItem>
      </IonList>
      <br></br>
      <IonList>
        <IonLabel style={{ margin: '10px 0', fontWeight: 'bold', fontSize: 14, marginLeft: 16, marginBottom: 20 }}>Monthly Membership Appointments</IonLabel>
        <IonItem lines='none'>
          <IonSelect
            interface="popover"
            mode='ios'
            className={commonStyles.inputStyle}
            value={subscriptionPackage.monthlyMembershipAppointments ?? null}
            onIonChange={(e) => {
              onValueChange('monthlyMembershipAppointments', e.detail.value)              
            }}
          >
            <IonSelectOption value={null}>Unlimited</IonSelectOption>
            <IonSelectOption value={1}>1 Appointment</IonSelectOption>
            <IonSelectOption value={2}>2 Appointments</IonSelectOption>
            <IonSelectOption value={3}>3 Appointments</IonSelectOption>
            <IonSelectOption value={4}>4 Appointments</IonSelectOption>
            <IonSelectOption value={5}>5 Appointments</IonSelectOption>
            <IonSelectOption value={6}>6 Appointments</IonSelectOption>
            <IonSelectOption value={7}>7 Appointments</IonSelectOption>
            <IonSelectOption value={8}>8 Appointments</IonSelectOption>
            <IonSelectOption value={9}>9 Appointments</IonSelectOption>
            <IonSelectOption value={10}>10 Appointments</IonSelectOption>
            <IonSelectOption value={11}>11 Appointments</IonSelectOption>
            <IonSelectOption value={12}>12 Appointments</IonSelectOption>
            <IonSelectOption value={13}>13 Appointments</IonSelectOption>
            <IonSelectOption value={14}>14 Appointments</IonSelectOption>
            <IonSelectOption value={15}>15 Appointments</IonSelectOption>
          </IonSelect>
        </IonItem>
        {subscriptionPackage.monthlyMembershipAppointments !== null && (
          <div style={{ marginTop: 10, marginLeft: 16 }}>
            <IonLabel position='stacked' style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 16 }}>Max Tokens Allowed</IonLabel>
            <IonItem lines='none'>

              <IonSelect
                interface="popover"
                mode='ios'
                className={commonStyles.inputStyle}
                value={subscriptionPackage.maxTokensAllowed ?? null}
                onIonChange={(e) => {
                  onValueChange('maxTokensAllowed', e.detail.value)
                }}
              >
                <IonSelectOption value={null}>Unlimited</IonSelectOption>
                <IonSelectOption value={1}>1 Token</IonSelectOption>
                <IonSelectOption value={2}>2 Tokens</IonSelectOption>
                <IonSelectOption value={3}>3 Tokens</IonSelectOption>
                <IonSelectOption value={4}>4 Tokens</IonSelectOption>
                <IonSelectOption value={5}>5 Tokens</IonSelectOption>
                <IonSelectOption value={6}>6 Tokens</IonSelectOption>
                <IonSelectOption value={7}>7 Tokens</IonSelectOption>
                <IonSelectOption value={8}>8 Tokens</IonSelectOption>
                <IonSelectOption value={9}>9 Tokens</IonSelectOption>
                <IonSelectOption value={10}>10 Tokens</IonSelectOption>
              </IonSelect>
            </IonItem>
          </div>

        )}
      </IonList>
    </>
  )
}
