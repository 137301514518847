import { IonButton, IonGrid, IonIcon, IonLabel, IonRow, IonSpinner } from '@ionic/react'
import { alertCircleOutline } from 'ionicons/icons'
import StandardCenterCenter from '../../commonComponents/StandardCenterCenter'
import { fontSizes, fontWeights } from '../../theme/typography'
import { colors } from '../../theme/colors'

export default function StepError({ creatingService, close, name, price, duration, republish }) {
  return (
    <StandardCenterCenter>
      <IonGrid>
        <StandardCenterCenter>
          {creatingService ? (
            <IonSpinner name='dots' />
          ) : (
            <>
              <IonRow style={{ marginBottom: 28 }}>
                <IonIcon style={{ height: 50, width: 50 }} icon={alertCircleOutline} color='danger' />
              </IonRow>
              <IonRow>
                <IonLabel style={{ fontSize: fontSizes.size32, fontWeight: fontWeights.weight600, lineHeight: fontSizes.size38, wordWrap: 'break-word' }}>
                  Something went wrong
                </IonLabel>
              </IonRow>

              <IonRow>
                <IonLabel
                  style={{
                    marginTop: 20,
                    fontSize: fontSizes.size18,
                    fontWeight: fontWeights.weight500,
                    lineHeight: fontSizes.size28,
                    color: colors.black70,
                    wordWrap: 'break-word',
                    textAlign: 'center',
                  }}
                >
                  {name} wasn’t published. Try again or save and come back to it later.
                </IonLabel>
              </IonRow>

              <IonButton onClick={republish} style={{ width: '100%', marginTop: 40 }} color='dark'>
                Try publishing again
              </IonButton>
              <IonButton onClick={close} style={{ width: '100%', marginTop: 10 }} fill='outline' color='dark'>
                Close
              </IonButton>
            </>
          )}
        </StandardCenterCenter>
      </IonGrid>
    </StandardCenterCenter>
  )
}
