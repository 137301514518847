import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSpinner,
  IonTextarea,
  IonToast,
  IonIcon,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router'
import { cancelSubscriptionOptions } from '../StaticData/StaticData'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { calculateNearestFutureSubscriptionEndDate, getFormattedMediumDate } from '../utils'
import { colors } from '../theme/colors'
import { checkmarkCircleOutline } from 'ionicons/icons'

export default function CancelSubscription({ match }) {
  const subscription_id = match?.params?.id
  const [cancellationType, setSelectedCancelOption] = useState('immediately')
  const cancelOptions = cancelSubscriptionOptions
  const [selectedValue, setSelectedValue] = useState('')
  const [cancellingMembership, setCancellingMembership] = useState(false)
  const [cancelledMembership, setCancelledMembership] = useState(false)
  const [subscriptionData, setSubscription] = useState(false)
  const [reason, setReason] = useState('')
  const history = useHistory()

  // Toast state
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })
  // Cumulative function to show toast messages
  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }
  const handleChange = (event) => {
    setSelectedValue(event.detail.value)
  }
  const handleReason = (event) => {
    setReason(event.detail.value)
  }
  const getSubscription = async () => {
    try {
      let response = await axios.get(`/subscription?id=${subscription_id}`)
      if (response && response.data) {
        setSubscription(response.data)
      }
    } catch (error) {
      console.error('Error fetching subscription.', error) // Log the error for debugging
    }
  }
  let nearestEndDate = false
  if (subscriptionData) {
    nearestEndDate = calculateNearestFutureSubscriptionEndDate(subscriptionData?.startDate, subscriptionData?.period?.toLowerCase())
  }

  const handleCancelMembership = async () => {
    setCancellingMembership(true)
    try {
      const cancelSubscriptionInput = {
        cancellationReason: selectedValue,
        writtenFeedback: reason,
        cancellationType,
        cancellationDate: cancellationType == 'immediately' ? getFormattedMediumDate(new Date()) : nearestEndDate,
      }

      const response = await axios.patch(`/subscription/cancel?id=${subscription_id}&customerId=${subscriptionData.customerId}`, cancelSubscriptionInput)

      if (response?.data?.success) {
        setCancelledMembership(true)
        showToastMessage('Membership cancelled successfully!', 'success')
      } else {
        showToastMessage('Cancellation failed. Please try again.', 'danger')
      }

      getSubscription()
    } catch (error) {
      showToastMessage('Error during cancellation. Please try again.', 'danger')
      console.error(error)
    } finally {
      setCancellingMembership(false)
    }
  }

  const handleCancellationType = () => {
    setSelectedCancelOption(cancellationType === 'immediately' ? 'endOfPeriod' : 'immediately')
  }

  const handleGoBackHome = () => {
    setCancellingMembership(false)
    setCancelledMembership(false)
    history.goBack()
  }
  useEffect(() => {
    getSubscription()
  }, [])

  return (
    <IonPage id='main-content'>
      <CommonHeader title={'Cancel Membership'} backIcon={!cancelledMembership}></CommonHeader>
      <IonContent style={{ '--background': '#f7f7f7' }}>
        <div style={{ maxWidth: '600px', margin: '0 auto', padding: '24px 16px' }}>
          {cancelledMembership ? (
            <div style={{
              background: 'white',
              borderRadius: '12px',
              padding: '32px',
              textAlign: 'center',
              boxShadow: '0 6px 16px rgba(0,0,0,0.08)',
              border: '1px solid #EBEBEB'
            }}>
              <div style={{
                width: '48px',
                height: '48px',
                borderRadius: '50%',
                background: '#E8F5E9',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto 24px'
              }}>
                <IonIcon icon={checkmarkCircleOutline} style={{ 
                  fontSize: '24px',
                  color: '#2E7D32'
                }} />
              </div>
              <h2 style={{ 
                fontSize: '20px',
                fontWeight: '600',
                color: '#222222',
                margin: '0 0 8px'
              }}>
                Membership Cancelled
              </h2>
              <p style={{
                color: '#717171',
                marginBottom: '24px'
              }}>
                The subscription has been successfully cancelled.
              </p>
              <IonButton
                expand="block"
                onClick={handleGoBackHome}
                style={{
                  '--background': '#3880ff',
                  '--background-activated': '#3171e0',
                  '--border-radius': '8px',
                  '--box-shadow': 'none',
                  height: '44px',
                  margin: 0,
                  fontWeight: '500',
                  maxWidth: '200px',
                  margin: '0 auto'
                }}
              >
                Back to Details
              </IonButton>
            </div>
          ) : (
            <div style={{
              background: 'white',
              borderRadius: '12px',
              padding: '32px',
              boxShadow: '0 6px 16px rgba(0,0,0,0.08)',
              border: '1px solid #EBEBEB'
            }}>
              <h2 style={{ 
                fontSize: '20px',
                fontWeight: '600',
                color: '#222222',
                margin: '0 0 24px'
              }}>
                Cancel Subscription
              </h2>

              {/* Cancellation Type */}
              <div style={{ marginBottom: '32px' }}>
                <div style={{ 
                  fontSize: '14px',
                  color: '#717171',
                  marginBottom: '16px'
                }}>
                  When should the cancellation take effect?
                </div>
                <IonRadioGroup 
                  value={cancellationType} 
                  onIonChange={handleCancellationType}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px'
                  }}
                >
                  {[
                    {
                      value: 'immediately',
                      label: 'Immediately',
                      date: getFormattedMediumDate(new Date())
                    },
                    {
                      value: 'endOfPeriod',
                      label: 'End of Period',
                      date: nearestEndDate
                    }
                  ].map(option => (
                    <div 
                      key={option.value}
                      onClick={() => setSelectedCancelOption(option.value)}
                      style={{
                        padding: '16px',
                        border: `1px solid ${cancellationType === option.value ? '#3880ff' : '#EBEBEB'}`,
                        borderRadius: '8px',
                        background: cancellationType === option.value ? '#F0F7FF' : 'white',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px'
                      }}
                    >
                      <IonRadio
                        mode="md"
                        value={option.value}
                        style={{
                          '--color-checked': '#3880ff',
                          margin: 0
                        }}
                      />
                      <div>
                        <div style={{
                          fontSize: '15px',
                          fontWeight: '500',
                          color: '#222222'
                        }}>
                          {option.label}
                        </div>
                        <div style={{
                          fontSize: '14px',
                          color: '#717171',
                          marginTop: '2px'
                        }}>
                          {option.date}
                        </div>
                      </div>
                    </div>
                  ))}
                </IonRadioGroup>
              </div>

              {/* Cancellation Reason */}
              <div>
                <div style={{ 
                  fontSize: '14px',
                  color: '#717171',
                  marginBottom: '16px'
                }}>
                  Why is the customer canceling their subscription?
                </div>
                <IonRadioGroup 
                  value={selectedValue} 
                  onIonChange={handleChange}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px'
                  }}
                >
                  {cancelOptions.map((item, index) => (
                    <div 
                      key={index}
                      onClick={() => setSelectedValue(item.value)}
                      style={{
                        padding: '16px',
                        border: `1px solid ${selectedValue === item.value ? '#3880ff' : '#EBEBEB'}`,
                        borderRadius: '8px',
                        background: selectedValue === item.value ? '#F0F7FF' : 'white',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px'
                      }}
                    >
                      <IonRadio
                        mode="md"
                        value={item.value}
                        style={{
                          '--color-checked': '#3880ff',
                          margin: 0
                        }}
                      />
                      <div style={{
                        fontSize: '15px',
                        fontWeight: '500',
                        color: '#222222'
                      }}>
                        {item.label}
                      </div>
                    </div>
                  ))}
                </IonRadioGroup>

                {selectedValue === 'other' && (
                  <div style={{ marginTop: '16px' }}>
                    <IonTextarea
                      value={reason}
                      onIonChange={handleReason}
                      placeholder="Please explain..."
                      rows={4}
                      style={{
                        '--background': '#f7f7f7',
                        '--border-radius': '8px',
                        '--padding-start': '16px',
                        '--padding-end': '16px',
                        '--padding-top': '12px',
                        '--padding-bottom': '12px'
                      }}
                    />
                  </div>
                )}
              </div>

              {/* Submit Button */}
              <div style={{ marginTop: '32px' }}>
                <IonButton
                  expand="block"
                  onClick={handleCancelMembership}
                  disabled={!selectedValue || cancellingMembership}
                  style={{
                    '--background': '#3880ff',
                    '--background-activated': '#3171e0',
                    '--border-radius': '8px',
                    '--box-shadow': 'none',
                    height: '44px',
                    margin: 0,
                    fontWeight: '500'
                  }}
                >
                  {cancellingMembership ? (
                    <>
                      <IonSpinner name="dots" style={{ marginRight: '8px' }} />
                      Cancelling Subscription...
                    </>
                  ) : (
                    'Submit and Cancel Subscription'
                  )}
                </IonButton>
              </div>
            </div>
          )}
        </div>

        <IonToast
          position="top"
          isOpen={toast.isOpen}
          onDidDismiss={() => setToast({ ...toast, isOpen: false })}
          message={toast.message}
          color={toast.color}
          duration={2000}
        />
      </IonContent>
    </IonPage>
  )
}
