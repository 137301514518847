import { IonButton, IonCheckbox, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow, IonSpinner } from '@ionic/react'
import { fontSizes, fontWeights } from '../../theme/typography'
import { checkmark } from 'ionicons/icons'

export default function StepMemberships({
  selectedPackages,
  subscriptionPackages,
  name,
  packagesLoading,
  handlePackage,
  handleIncludeInMemberships,
  isIncludedInMembership,
}) {
  return (
    <IonGrid>
      <IonRow>
        <IonLabel
          style={{
            fontSize: fontSizes.size28,
            fontWeight: fontWeights.weight600,
            lineHeight: '33.6px',
            wordWrap: 'break-word',
            marginBottom: fontSizes.size16,
          }}
        >
          Is {name} included in any membership package?
        </IonLabel>
      </IonRow>
      <IonRow style={{ width: '100%', marginTop: 20 }}>
        <IonButton
          onClick={() => handleIncludeInMemberships(true)}
          fill={isIncludedInMembership ? null : 'outline'}
          style={{ marginRight: 20, width: '25%' }}
          color={'dark'}
        >
          <IonLabel style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {isIncludedInMembership && <IonIcon icon={checkmark} style={{ marginRight: 10 }} />}
            Yes
          </IonLabel>
        </IonButton>
        <IonButton style={{ width: '25%' }} fill={isIncludedInMembership ? 'outline' : null} onClick={() => handleIncludeInMemberships(false)} color={'dark'}>
          <IonLabel style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {!isIncludedInMembership && <IonIcon icon={checkmark} style={{ marginRight: 10 }} />}
            No
          </IonLabel>
        </IonButton>
      </IonRow>
      {isIncludedInMembership && (
        <>
          {packagesLoading ? (
            <IonSpinner name='dots' />
          ) : (
            <div style={{ marginTop: 20 }}>
              <IonLabel
                style={{
                  fontSize: fontSizes.size16,
                  fontWeight: fontWeights.weight700,
                  lineHeight: fontSizes.size24,
                  letterSpacing: 1.6,
                  wordWrap: 'break-word',
                }}
              >
                SELECT PLANS
              </IonLabel>
              <IonList>
                {subscriptionPackages?.map((item, index) => {
                  return (
                    <IonItem onClick={() => handlePackage(item._id)}>
                      <IonLabel>{item.name}</IonLabel>
                      <IonCheckbox slot='end' checked={selectedPackages.includes(item._id)} onIonChange={(e) => handlePackage(item._id)} />
                    </IonItem>
                  )
                })}
              </IonList>
            </div>
          )}
        </>
      )}
    </IonGrid>
  )
}
