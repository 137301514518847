import { IonCol, IonContent, IonGrid, IonLabel, IonModal, IonRow } from '@ionic/react'
import { fontSizes, fontWeights } from '../../theme/typography'
import { useContext, useState } from 'react'
import { AuthContext } from '../../auth'
import B64Image from '../../commonComponents/b64Image'
import StepProfessionals from './StepProfessionals'
import StandardContainer from '../../commonComponents/StandardContainer'
import CommonHeader from '../../commonComponents/CommonHeader'
import { colors } from '../../theme/colors'

export default function PreviewProfessionals({ employees, selectedEmployees, employeesLoading, setSelectedEmployees }) {
  const { businessData } = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false)
  const [tempSelectedEmployees, setTempSelectedEmployees] = useState([...selectedEmployees])
  const [professionalsDataChanged, setProfessionalsDataChanged] = useState(false)
  const employeesSelected = employees?.filter((item) => tempSelectedEmployees?.includes(item?._id)) || []

  const openModal = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
    setTempSelectedEmployees([...selectedEmployees])
  }
  const handleSelectedEmployees = (employees) => {
    setTempSelectedEmployees(employees)
    setProfessionalsDataChanged(true)
  }
  const handleSaveProfessionals = () => {
    setSelectedEmployees(tempSelectedEmployees)
    setShowModal(false)
    setProfessionalsDataChanged(false)
  }
  return (
    <>
      <IonGrid style={{ marginTop: 28 }}>
        <IonRow style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
          <IonCol style={{ padding: 0 }}>
            <IonLabel
              style={{
                fontSize: fontSizes.size16,
                fontWeight: fontWeights.weight700,
                textTransform: 'uppercase',
                lineHeight: fontSizes.size24,
                letterSpacing: 1.6,
                wordWrap: 'break-word',
              }}
            >
              Who can perform this service{' '}
            </IonLabel>
          </IonCol>
          <IonCol onClick={openModal} size='auto' style={{ padding: 0, cursor: 'pointer' }}>
            <IonLabel
              style={{
                fontSize: fontSizes.size18,
                fontWeight: fontWeights.weight600,
                textDecoration: 'underline',
                lineHeight: fontSizes.size28,
                wordWrap: 'break-word',
              }}
            >
              Change
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow style={{ marginTop: 24, position: 'relative' }}>
          {employeesSelected?.length > 0 ? (
            <>
              {employeesSelected?.map((item, index) => {
                return (
                  <div
                    style={{
                      height: 44,
                      width: 44,
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'white',
                      position: 'absolute',
                      left: index != 0 ? index * 35 : 0,
                    }}
                  >
                    <B64Image height={40} width={40} url={`/employee/profilePic?employeeId=${item?._id}&businessId=${businessData?._id}`} />
                  </div>
                )
              })}
            </>
          ) : (
            <IonRow>
              <IonLabel style={{ color: colors.black50 }}>Not performed by any professional</IonLabel>
            </IonRow>
          )}
        </IonRow>
      </IonGrid>
      <IonModal isOpen={showModal} onDidDismiss={closeModal}>
        <IonContent>
          <CommonHeader
            saveButton={true}
            handleSaveButton={handleSaveProfessionals}
            saveDisabled={!professionalsDataChanged}
            closeButton={true}
            closeModal={() => closeModal()}
          />
          <StandardContainer padding={20}>
            {' '}
            <StepProfessionals
              employeesLoading={employeesLoading}
              employees={employees}
              selectedEmployees={tempSelectedEmployees}
              setSelectedEmployees={handleSelectedEmployees}
            />
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}
