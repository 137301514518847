import { IonGrid, IonLabel, IonRow } from '@ionic/react'
import { fontSizes, fontWeights } from '../../theme/typography'
import { colors } from '../../theme/colors'

export default function Step1Summary() {
  return (
    <IonGrid>
      <IonRow style={{ display: 'flex', justifyContent: 'center' }}>
        <img style={{ height: '20rem' }} src={'/assets/step-1-summary.png'} alt='' />
      </IonRow>
      <IonRow style={{ marginBottom: 10 }}>
        <IonLabel
          style={{
            color: colors.black60,
            fontSize: fontSizes.size14,
            fontWeight: fontWeights.weight700,
            textTransform: 'uppercase',
            lineHeight: fontSizes.size18,
            letterSpacing: 1.4,
            wordWrap: 'break-word',
          }}
        >
          Step 1
        </IonLabel>
      </IonRow>
      <IonRow style={{ marginBottom: 10 }}>
        <IonLabel
          style={{
            alignSelf: 'stretch',
            fontSize: fontSizes.size32,
            fontWeight: fontWeights.weight600,
            lineHeight: '38.4px',
            wordWrap: 'break-word',
          }}
        >
          Describe the new service
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonLabel style={{ color: colors.black60, fontSize: fontSizes.size18, fontWeight: fontWeights.weight400, lineHeight: '28px' }}>
          Give some basic info like how long it takes to perform this service, set your price and specify who can offer this service.
        </IonLabel>
      </IonRow>
    </IonGrid>
  )
}
