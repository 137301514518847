import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonLabel, IonRow, IonSpinner } from '@ionic/react'
import StandardContainer from '../../commonComponents/StandardContainer'
import { fontSizes, fontWeights } from '../../theme/typography'
import { cashOutline, createOutline, removeCircleOutline } from 'ionicons/icons'
import { colors } from '../../theme/colors'

export default function CancelAppointmentForm({
  locationCancelationSettings,
  defaultCancellationCharges,
  defaultChargeEnabled,
  appointment,
  customChargeValue,
  handleCustomChargeValue,
  error,
  closeModal,
  loading,
  handleCancelAppointment,
  cardOnFile,
  selectedType,
  handleFeeType,
}) {
  const currentTime = new Date().getTime()
  const timeDifferenceInHours = (appointment?.startTime - currentTime) / (60 * 60 * 1000)
  const cancellationAllowed = timeDifferenceInHours > (locationCancelationSettings?.hoursBefore || 24)
  const isCancellationChargeable = cancellationAllowed || !cardOnFile || (!locationCancelationSettings?.charge && appointment?.agreedToCancelPolicy)
  const canSubmitCancellation = (selectedType == 'default' || selectedType == 'noFee' || customChargeValue !== '') && !error
  const handleSelectedType = (type) => {
    handleFeeType(type)
  }
  let cancelText = ''
  if (selectedType == 'custom') {
    cancelText = `Confirm Cancel & Charge $${customChargeValue}`
  }
  if (selectedType == 'default') {
    cancelText = `Confirm Cancel & Charge $${defaultCancellationCharges} `
  }
  if (selectedType == 'noFee') {
    cancelText = 'Confirm Cancel Appointment'
  }

  return (
    <IonContent className='regularBackground'>
      <StandardContainer padding={20}>
        <div style={{ marginTop: '2rem', padding: '1rem', display: 'flex', flexDirection: 'column' }}>
          <IonLabel style={{ fontSize: fontSizes.size25, marginBottom: 20, fontWeight: fontWeights.weight600 }}>Cancel Appointment</IonLabel>
          <IonLabel style={{ fontWeight: 'bold' }}>
            {isCancellationChargeable
              ? `Since the appointment is being cancelled within  ${
                  locationCancelationSettings?.hoursBefore || 24
                } hours of the appointment, a fee of $${defaultCancellationCharges} is charged`
              : 'Are you sure you want to cancel?'}
          </IonLabel>
          <IonGrid style={{ width: '100%', marginTop: 30 }}>
            <IonRow
              onClick={() => handleSelectedType('default')}
              style={{
                padding: 10,
                marginBottom: 10,
                boxShadow: selectedType == 'default' ? 'rgb(0 0 0 / 13%) 0px 5px 15px' : null,
                border: `2px solid ${selectedType == 'default' ? colors.black60 : colors.black10}`,
                borderRadius: 5,
                display: 'Flex',
                alignItems: 'center',
              }}
            >
              <IonLabel style={{ display: 'flex', justifyContent: 'cente', alignItems: 'center' }}>
                <IonIcon style={{ marginRight: 10 }} icon={cashOutline} /> Charge ${defaultCancellationCharges} fee
              </IonLabel>
            </IonRow>
            <IonRow
              onClick={() => handleSelectedType('custom')}
              style={{
                padding: 10,
                marginBottom: 10,
                boxShadow: selectedType == 'custom' ? 'rgb(0 0 0 / 13%) 0px 5px 15px' : null,

                border: `2px solid ${selectedType == 'custom' ? colors.black60 : colors.black10}`,
                borderRadius: 5,
                display: 'Flex',
                alignItems: 'center',
              }}
            >
              <IonLabel style={{ display: 'flex', justifyContent: 'cente', alignItems: 'center' }}>
                <IonIcon style={{ marginRight: 10 }} icon={createOutline} /> Charge custom fee
              </IonLabel>
              {selectedType == 'custom' && (
                <div style={{ width: '100%' }}>
                  <IonRow style={{ margin: 0, padding: 0 }}>
                    <IonCol>
                      <IonInput
                        type='number'
                        value={customChargeValue}
                        onIonInput={(e) => handleCustomChargeValue(e.detail.value)}
                        placeholder='0'
                        inputMode='decimal'
                      />
                      {error && <IonLabel style={{ width: '100%', margin: '1rem  0', color: 'red', fontSize: '12px' }}>{error}</IonLabel>}
                    </IonCol>
                  </IonRow>
                </div>
              )}
            </IonRow>
            <IonRow
              onClick={() => handleSelectedType('noFee')}
              style={{
                padding: 10,
                marginBottom: 10,
                boxShadow: selectedType == 'noFee' ? 'rgb(0 0 0 / 13%) 0px 5px 15px' : null,
                border: `2px solid ${selectedType == 'noFee' ? colors.black60 : colors.black10}`,
                borderRadius: 5,
                display: 'Flex',
                alignItems: 'center',
              }}
            >
              <IonLabel style={{ display: 'flex', justifyContent: 'cente', alignItems: 'center' }}>
                <IonIcon style={{ marginRight: 10 }} icon={removeCircleOutline} />
                No fee
              </IonLabel>
            </IonRow>
          </IonGrid>

          <div style={{ marginTop: '1rem', borderTop: '1px solid #d3d3d3' }}></div>
          {loading && <IonSpinner name='lines' />}
          {!loading && (
            <IonButton
              style={{ width: '100%', marginTop: 40 }}
              color='dark'
              disabled={isCancellationChargeable && !canSubmitCancellation}
              onClick={handleCancelAppointment}
            >
              {cancelText}{' '}
            </IonButton>
          )}
          <IonButton onClick={closeModal} style={{ width: '100%', marginTop: 10 }} color={'dark'} fill='outline'>
            Close without Cancelling
          </IonButton>
        </div>
      </StandardContainer>
    </IonContent>
  )
}
