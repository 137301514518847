import { IonButton, IonButtons, IonContent, IonModal, IonSpinner } from '@ionic/react'
import React from 'react'

export default function ConfirmationModal({ isOpen, message, cancel, loading, save, saveText }) {
  return (
    <IonContent>
      <div
        style={{
          padding: '2rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '400px',
          margin: '0 auto',
        }}
      >
        <h2 
          style={{ 
            textAlign: 'center',
            fontSize: '1.5rem',
            fontWeight: '600',
            color: '#333',
            marginBottom: '1.5rem',
          }}
        >
          {message}
        </h2>
        <IonButtons
          slot='end'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
            width: '100%',
          }}
        >
          <IonButton
            // mode='ios'
            onClick={() => cancel()} 
            style={{ 
              '--background': '#f4f5f8',
              '--color': '#666',
              '--border-radius': '6px',
              '--padding-start': '2rem',
              '--padding-end': '2rem',
            }}
            fill="solid"
          >
            Cancel
          </IonButton>
          {loading && <IonSpinner name='lines' color="primary" />}
          {!loading && (
            <IonButton 
              // mode='ios'
              color={'primary'} 
              onClick={save}
              style={{
                '--border-radius': '6px',
                '--padding-start': '2rem',
                '--padding-end': '2rem',
              }}
              fill="solid"
            >
              {saveText}
            </IonButton>
          )}
        </IonButtons>
      </div>
    </IonContent>
  )
}

