import { IonGrid, IonLabel, IonRow } from '@ionic/react'
import { fontSizes, fontWeights } from '../../theme/typography'
import { colors } from '../../theme/colors'

export default function StepFinishAndPublish() {
  return (
    <IonGrid>
      <IonRow style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={'/assets/step-3-summary.png'} alt='' />
      </IonRow>
      <IonRow style={{ marginBottom: 10 }}>
        <IonLabel
          style={{
            color: colors.black60,
            fontSize: fontSizes.size14,
            fontWeight: fontWeights.weight700,
            textTransform: 'uppercase',
            lineHeight: fontSizes.size18,
            letterSpacing: 1.4,
            wordWrap: 'break-word',
          }}
        >
          Step 2
        </IonLabel>
      </IonRow>
      <IonRow style={{ marginBottom: 10 }}>
        <IonLabel
          style={{
            alignSelf: 'stretch',
            fontSize: fontSizes.size32,
            fontWeight: fontWeights.weight600,
            lineHeight: '38.4px',
            wordWrap: 'break-word',
          }}
        >
          Finish up and publish
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonLabel style={{ color: colors.black60, fontSize: fontSizes.size18, fontWeight: fontWeights.weight400, lineHeight: '28px' }}>
          Add any discounts and publish your listing
        </IonLabel>
      </IonRow>
    </IonGrid>
  )
}
