import { IonCol, IonGrid, IonLabel, IonPicker, IonPickerColumn, IonPickerColumnOption, IonRow } from '@ionic/react'
import { fontSizes, fontWeights } from '../../theme/typography'
import { colors } from '../../theme/colors'
export default function StepDuration({ name, handleSelectedTime, selectedTime }) {
  // Generate Hours: 0 - 10
  const hours = Array.from({ length: 11 }, (_, i) => ({ text: i.toString(), value: i }))

  // Generate Minutes: 00 - 55 (Interval of 5)
  const minutes = Array.from({ length: 12 }, (_, i) => ({
    text: (i * 5).toString().padStart(2, '0'),
    value: (i * 5).toString().padStart(2, '0'),
  }))
  return (
    <IonGrid>
      <IonRow>
        <IonLabel
          style={{
            fontSize: fontSizes.size28,
            fontWeight: fontWeights.weight600,
            lineHeight: '33.6px',
            wordWrap: 'break-word',
            marginBottom: fontSizes.size16,
          }}
        >
          How long does it take to perform {name} ?
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonLabel
          style={{
            marginBottom: fontSizes.size16,
            color: colors.black80,
            fontSize: fontSizes.size18,
            lineHeight: '28px',
          }}
        >
          This will block off this amount of time when booking this service.
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonCol style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <IonLabel
            style={{
              fontSize: fontSizes.size16,
              fontWeight: fontWeights.weight500,
              lineHeight: fontSizes.size24,
            }}
          >
            Hours
          </IonLabel>
          <IonPicker>
            <IonPickerColumn onIonChange={(e) => handleSelectedTime('hour', e.target.value)} value={selectedTime.hour}>
              {hours.map((item, index) => {
                return <IonPickerColumnOption value={item.value}>{item.text}</IonPickerColumnOption>
              })}
            </IonPickerColumn>
          </IonPicker>
        </IonCol>
        <IonCol style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <IonLabel
            style={{
              fontSize: fontSizes.size16,
              fontWeight: fontWeights.weight500,
              lineHeight: fontSizes.size24,
            }}
          >
            Minutes
          </IonLabel>

          <IonPicker>
            <IonPickerColumn onIonChange={(e) => handleSelectedTime('minute', e.target.value)} value={selectedTime.minute}>
              {minutes.map((item, index) => {
                return <IonPickerColumnOption value={item.value}>{item.text}</IonPickerColumnOption>
              })}
            </IonPickerColumn>
          </IonPicker>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}
