import { IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonModal, IonRow } from '@ionic/react'
import { fontSizes, fontWeights } from '../../theme/typography'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/StandardContainer'
import { close } from 'ionicons/icons'
import { colors } from '../../theme/colors'
import StepMemberships from './StepMemberships'
import { useState } from 'react'

export default function PreviewMemberships({
  name,
  packagesLoading,
  subscriptionPackages,
  selectedPackages,
  isIncludedInMembership,
  setSelectedPackages,
  setIsIncludedInMembership,
}) {
  const [showPackagesModal, setShowPackagesModal] = useState(false)
  const [tempSelectedPackages, setTempSelectedPackages] = useState([...selectedPackages])
  const [tempIsIncludedInMembership, setTempIsIncludedInMembership] = useState(isIncludedInMembership)
  const [packagesDataChanged, setPackagesDataChanged] = useState(false)
  const handlePackage = (item) => {
    setPackagesDataChanged(true)
    setTempSelectedPackages((prev) => (prev.includes(item) ? prev.filter((pkg) => pkg !== item) : [...prev, item]))
  }
  const handleRemovePackage = (id) => {
    let pkgs = [...tempSelectedPackages]
    const filteredpkgs = pkgs.includes(id) ? pkgs.filter((pkg) => pkg !== id) : [...pkgs, id]
    setSelectedPackages(filteredpkgs)
    setTempSelectedPackages(filteredpkgs)
  }
  const handleIncludeInMemberships = (isIncluded) => {
    setPackagesDataChanged(true)
    setTempIsIncludedInMembership(isIncluded)
    setTempSelectedPackages([])
  }
  const closePackagesModal = () => {
    setShowPackagesModal(false)
    setTempSelectedPackages([...selectedPackages])
    setTempIsIncludedInMembership(isIncludedInMembership)
  }
  const handleSavePackages = () => {
    setSelectedPackages(tempSelectedPackages)
    setIsIncludedInMembership(tempIsIncludedInMembership)
    setShowPackagesModal(false)
    setPackagesDataChanged(false)
  }
  const packagesSelected = subscriptionPackages?.filter((item, index) => tempSelectedPackages?.includes(item?._id)) || []

  return (
    <>
      <IonGrid style={{ marginTop: 32, padding: 0 }}>
        <IonRow style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
          <IonCol style={{ padding: 0 }}>
            <IonLabel
              style={{
                fontSize: fontSizes.size16,
                fontWeight: fontWeights.weight700,
                textTransform: 'uppercase',
                lineHeight: fontSizes.size24,
                letterSpacing: 1.6,
                wordWrap: 'break-word',
              }}
            >
              Included memberships
            </IonLabel>
          </IonCol>
          <IonCol onClick={() => setShowPackagesModal(true)} size='auto' style={{ padding: 0, cursor: 'pointer' }}>
            <IonLabel
              style={{
                fontSize: fontSizes.size18,
                fontWeight: fontWeights.weight600,
                textDecoration: 'underline',
                lineHeight: fontSizes.size28,
                wordWrap: 'break-word',
              }}
            >
              Change
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow style={{ marginTop: 16 }}>
          {packagesSelected?.length > 0 ? (
            <>
              {packagesSelected.map((item, index) => {
                return (
                  <IonLabel
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: 8,
                      marginBottom: 8,
                      padding: '3px 10px',
                      background: index % 2 == 0 ? colors.black : '#4E5BA6',
                      borderRadius: 999,
                      color: colors.white,
                      fontSize: fontSizes.size16,
                      fontWeight: fontWeights.weight600,
                    }}
                  >
                    {item.name}
                    <IonIcon onClick={() => handleRemovePackage(item._id)} style={{ cursor: 'pointer', marginLeft: 10 }} icon={close} />
                  </IonLabel>
                )
              })}
            </>
          ) : (
            <IonRow>
              <IonLabel style={{ color: colors.black50 }}>Not Included In Any Memberships</IonLabel>
            </IonRow>
          )}
        </IonRow>
      </IonGrid>
      <IonModal isOpen={showPackagesModal} onDidDismiss={() => closePackagesModal()}>
        <IonContent>
          <CommonHeader
            saveButton={true}
            handleSaveButton={handleSavePackages}
            saveDisabled={!packagesDataChanged || (tempIsIncludedInMembership && tempSelectedPackages.length === 0)}
            closeButton={true}
            closeModal={() => closePackagesModal()}
          />
          <StandardContainer padding={20}>
            <StepMemberships
              selectedPackages={tempSelectedPackages}
              subscriptionPackages={subscriptionPackages}
              name={name}
              packagesLoading={packagesLoading}
              handlePackage={handlePackage}
              handleIncludeInMemberships={handleIncludeInMemberships}
              isIncludedInMembership={tempIsIncludedInMembership}
            />{' '}
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}
