import { IonButton, IonCol, IonContent, IonInput, IonPage, IonRow, IonSpinner, IonIcon } from '@ionic/react'
import { useContext, useEffect, useState, useRef } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import axios from 'axios'
import Conversation from './Conversation'
import { AuthContext } from '../auth'
import { useHistory } from 'react-router'
import { arrowUp } from 'ionicons/icons'

export default function ConversationDetails({ match }) {
  const [sendingMessage, setSendingMessage] = useState(false)
  const customerId = match?.params?.id
  const [twilioConversationId, setTwilioConversationId] = useState(null)
  const [conversation, setConversation] = useState(false)
  const [loadingConversation, setLoadingConversation] = useState(false)
  const pageSize = 20
  const [pageNumber, setPageNumber] = useState(1)
  const [draft, setDraft] = useState('')
  const { locationId, businessData, userData } = useContext(AuthContext)
  const [messages, setMessages] = useState([])
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const [pageToken, setPageToken] = useState(null)
  const [totalMessages, setTotalMessages] = useState(0)
  const [loadingMessages, setLoadingMessages] = useState(false)
  const [polling, setPolling] = useState(false)

  const messagesRef = useRef(messages);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    const getConversationConfig = async () => {
      if(customerId){
        const conversationConfiguration = await axios.get(`/conversation/customerConfiguration?customerId=${customerId}`)
        if(conversationConfiguration?.data?.twilioConversationId){
          setTwilioConversationId(conversationConfiguration?.data?.twilioConversationId)
        }
      }
    }

    getConversationConfig()
  },[customerId])
  


  const getConversationMessages = async (twilioConversationId, pageToken, showLoader) => {
    if (showLoader) {
      setLoadingConversation(true)
    } else {
      setLoadingMessages(true)
    }
    try {
      const conversationResponse = await axios.get(
        `/conversation/message?twilioConversationId=${twilioConversationId}&pageToken=${pageToken}&pageSize=${pageSize}`
      )
      if (conversationResponse.status == 200) {
        setTotalMessages(conversationResponse?.data?.total)
        if (showLoader) {
          setLoadingConversation(false)
        } else {
          setLoadingMessages(false)
        }
        const messagesSortedBasedOnCreatedTime = conversationResponse?.data?.messages.sort((a, b) => a.createdTimestamp - b.createdTimestamp)
        const sortedExistingMessages = messages.sort((a, b) => a.createdTimestamp - b.createdTimestamp)
        const combinedMessages = messagesSortedBasedOnCreatedTime.concat(sortedExistingMessages)
        setMessages(pageToken == null ? messagesSortedBasedOnCreatedTime : combinedMessages)
        setConversation(conversationResponse?.data)
        setPageToken(conversationResponse?.data?.nextPageToken)
      }
    } catch (error) {}
  }
  // Function to handle input changes
  const handleInputChange = (event) => {
    setDraft(event.detail.value)
  }
  const updateMessageStatus = async (messageId) => {
    try {
      const response = await axios.patch(`/conversation/message?id=${messageId}`, {
        status: 'read',
      })

      return response.data // Return the response data if needed
    } catch (error) {
      console.error(error)
      throw new Error('Failed to update message status')
    }
  }
  // Function to send a message
  const sendMessage = async () => {
    if (draft.trim() !== '') {
      setSendingMessage(true)
      try {
        const messageInput = {
          locationId: locationId,
          twilioConversationId: conversation.twilioConversationId,
          twilioConversationId: twilioConversationId,
          conversationId: conversation.conversationId,
          body: draft,
          author: userData?._id,
          authorType: 'User',
        }

        const response = await axios.post('/conversation/message', messageInput)
        if (response.status == 200) {
          const unreadMessages = messages ? messages.filter((item, index) => item.status == 'unread' && item.authorType === 'Customer') : []
          if (unreadMessages && unreadMessages.length > 0) {
            unreadMessages.forEach(async (message) => {
              await updateMessageStatus(message._id) // Assuming 'id' is the unique identifier of the message
            })
          }

          setSendingMessage(false)
          getConversationMessages(twilioConversationId, null, false)
        }
      } catch (error) {}

      setDraft('')
    }
  }
  const handleScroll = async (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target

    // Store the current scroll position
    const currentScrollPosition = scrollTop

    if (scrollTop == 0 && !loading && messages.length < totalMessages) {
      await getConversationMessages(twilioConversationId, pageToken, false)

      // Set the scroll position back to its previous value after loading new messages
      // event.target.scrollTop = currentScrollPosition
    }
  }
  useEffect(() => {
    if(twilioConversationId){
      getConversationMessages(twilioConversationId, null, true)
    }
  }, [twilioConversationId])

  useEffect(() => {
    let pollInterval;

    const pollForNewMessages = async () => {
      if (twilioConversationId) {
        try {
          const conversationResponse = await axios.get(
            `/conversation/message?twilioConversationId=${twilioConversationId}&pageToken=null&pageSize=${pageSize}`
          );
          
          if (conversationResponse.status === 200) {
            const newMessages = conversationResponse.data.messages;
            if (newMessages.length > messagesRef.current.length) {
              setMessages(newMessages);
              setTotalMessages(conversationResponse.data.total);
              setConversation(conversationResponse.data);
            }
          }
        } catch (error) {
          console.error('Error polling for new messages:', error);
        }
      }
    };

    // Start polling when we have a conversation ID
    if (twilioConversationId && !polling) {
      setPolling(true);
      pollInterval = setInterval(pollForNewMessages, 10000);
    }

    // Cleanup function
    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
        setPolling(false);
        console.log('polling stopped')
      }
    };
  }, [twilioConversationId]);

  const title = !loadingConversation ? conversation?.customerFirstName + ' ' + conversation?.customerLastName : ''
  return (
    <IonPage id='main-content'>
      <CommonHeader title={title} backIcon={true}></CommonHeader>
      <IonContent className='regularBackground'>
        <div className='container'>
          <div className='content'>
            <div class='message-wrap' onScroll={handleScroll} style={{ padding: '1rem', height: '100%' }}>
              {loadingMessages && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <IonSpinner name='dots' />
                </div>
              )}
              <Conversation mobileView={true} placeholderCount={7} loadingConversation={loadingConversation} messages={messages} />
            </div>
            <div className="message-footer">
              <div className="message-input-container">
                <IonInput 
                  className="message-input"
                  disabled={sendingMessage} 
                  value={draft || ''}
                  placeholder='Message'
                  onIonInput={handleInputChange}
                  onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && sendMessage()}
                  autoGrow={true}
                  inputmode="text"
                />
                <IonButton 
                  className="send-button"
                  disabled={!draft || sendingMessage} 
                  onClick={sendMessage}
                >
                  {sendingMessage ? (
                    <IonSpinner name="dots" />
                  ) : (
                    <IonIcon icon={arrowUp} />
                  )}
                </IonButton>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}
