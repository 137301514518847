const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const { statusColors } = require('./StaticData/StaticData')
dayjs.extend(utc)
dayjs.extend(timezone)
exports.getFormattedDate = (date) => {
  var d = new Date(date)
  var minutes = d.getMinutes().toString().length == 1 ? '0' + d.getMinutes() : d.getMinutes(),
    hours = d.getHours().toString().length == 1 ? '0' + d.getHours() : d.getHours(),
    ampm = d.getHours() >= 12 ? 'pm' : 'am',
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  return days[d.getDay()] + ' ' + months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear() + ' ' + hours + ':' + minutes + ampm
}
exports.getFormattedDateWithAmPm = (date) => {
  const formattedDate =
    `${(date.getMonth() + 1).toString().padStart(2, '0')}/` +
    `${date.getDate().toString().padStart(2, '0')}/` +
    `${date.getFullYear()} ` +
    `${date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })}`
  return formattedDate
}

exports.getFormattedMediumDate = (date) => {
  var d = new Date(date)
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear()
}

exports.getFormattedMediumDateWithoutTimeZone = (date) => {
  const formattedDate = dayjs(date).format('MMM D, YYYY')
  return formattedDate
}

exports.getFormattedMediumDateNoYear = (date) => {
  var d = new Date(date)
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return months[d.getMonth()] + ' ' + d.getDate()
}

exports.getWeekday = (date) => {
  let d = new Date(date)
  let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  return days[d.getDay()]
}

exports.getShortWeekday = (date) => {
  let d = new Date(date)
  let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return days[d.getDay()]
}

exports.formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

exports.stringToColor = (string) => {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

exports.pickTextColorBasedOnBgColorAdvanced = (bgColor, lightColor, darkColor) => {
  var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
  var r = parseInt(color.substring(0, 2), 16) // hexToR
  var g = parseInt(color.substring(2, 4), 16) // hexToG
  var b = parseInt(color.substring(4, 6), 16) // hexToB
  var uicolors = [r / 255, g / 255, b / 255]
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92
    }
    return Math.pow((col + 0.055) / 1.055, 2.4)
  })
  var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  return L > 0.179 ? darkColor : lightColor
}

exports.setTimeToDate = (inputDate, timeString) => {
  let d = inputDate ? new Date(inputDate) : new Date(),
    s = timeString,
    parts = s.match(/(\d+)\:(\d+) (\w+)/),
    hours = /am/i.test(parts[3].toLowerCase()) || parseInt(parts[1], 10) == 12 ? parseInt(parts[1], 10) : parseInt(parts[1], 10) + 12,
    minutes = parseInt(parts[2], 10)

  d.setHours(hours)
  d.setMinutes(minutes)
  d.setSeconds(0)
  d.setMilliseconds(0)

  return d
}

exports.numberWithCommas = (x) => {
  let parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  if (parts[1]) {
    if (parts[1].substr(0, 2) === '00') {
      return parts[0]
    }
    return parts[0] + '.' + parts[1].substr(0, 2)
  }

  return parts[0]
}

exports.convertDataToCSV = (data) => {
  // const  = typeof data !== 'object' ? JSON.parse(data) : data
  if (!Array.isArray(data) || data.length === 0) {
    return
  }

  const headers = Object.keys(data[0])
  let csvContent = headers.join(',') + '\r\n'

  data.forEach((object) => {
    let line = ''
    headers.forEach((header, index) => {
      if (index > 0) line += ','
      line += object[header]
    })
    csvContent += line + '\r\n'
  })

  return csvContent
}

exports.convertMinutesToHoursAndMinutes = (minutes) => {
  const hours = Math.floor(minutes / 60) // Calculate hours
  const remainingMinutes = minutes % 60 // Calculate remaining minutes

  let result = ''

  if (hours > 0) {
    result += `${hours} hr${hours > 1 ? 's' : ''}` // Add 'hour' or 'hours' based on the value
    if (remainingMinutes > 0) {
      result += ' ' // Add 'and' if there are both hours and minutes
    }
  }

  if (remainingMinutes > 0) {
    result += `${remainingMinutes} min${remainingMinutes > 1 ? 's' : ''}` // Add 'minute' or 'minutes' based on the value
  }

  return result
}

exports.validateUrl = (inputUrl) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  const isValidUrl = !!pattern.test(inputUrl)
  return isValidUrl
}

exports.formatDate = (date) => {
  const options = { month: 'long', day: 'numeric' }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

exports.getMonthFromDate = (date = new Date()) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  const monthIndex = date.getMonth()
  return months[monthIndex]
}
exports.getFormattedDateFromDateObject = (date) => {
  // Create a Date object from the input string
  const dateObject = new Date(date)

  // Format the date as YYYY-MM-DD
  const formattedDate = dateObject.toISOString().slice(0, 10)
  return formattedDate
}
exports.generateMonthYearArray = (startDate, viewMode) => {
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0) // Remove time component

  //

  const start = new Date(startDate)
  start.setDate(1) // Start of the given month
  start.setHours(0, 0, 0, 0) // Remove time component

  //

  const monthYearArray = []

  while (currentDate >= start) {
    const month = start.getMonth()
    const year = start.getFullYear()

    if (viewMode === 'monthly') {
      const startDate1 = new Date(year, month, 1)
      const endDate1 = new Date(year, month + 1, 0)
      monthYearArray.push({ startDate: startDate1, endDate: endDate1 })
    } else if (viewMode === 'payPeriod') {
      const startDate1 = new Date(year, month, 1)
      const endDate1 = new Date(year, month, 15)
      const startDate2 = new Date(year, month, 16)
      const endDate2 = new Date(year, month + 1, 0)

      if (currentDate.getFullYear() === year && currentDate.getMonth() === month) {
        // If we're within the first pay period of the current month
        if (currentDate.getDate() <= 15) {
          monthYearArray.push({ startDate: startDate1, endDate: endDate1 })
        } else {
          monthYearArray.push({ startDate: startDate1, endDate: endDate1 })
          monthYearArray.push({ startDate: startDate2, endDate: endDate2 })
        }
      } else {
        monthYearArray.push({ startDate: startDate1, endDate: endDate1 })
        monthYearArray.push({ startDate: startDate2, endDate: endDate2 })
      }
    }

    // Move to the previous month
    start.setMonth(month + 1)
  }

  return monthYearArray.reverse()
}
exports.dataURLtoBlob = (dataURL) => {
  const arr = dataURL.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new Blob([u8arr], { type: mime })
}

exports.getDaysInCurrentMonth = () => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()

  const firstDayOfMonth = new Date(currentYear, currentMonth, 1)
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0)

  const daysArray = []
  for (let date = firstDayOfMonth; date <= lastDayOfMonth; date.setDate(date.getDate() + 1)) {
    const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
    })
    daysArray.push(formattedDate)
  }

  return daysArray
}
exports.getAllDaysInCurrentMonth = (noOfMonths) => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() - noOfMonths // Adjust the month based on noOfMonths
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1)
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0)

  return Array.from({ length: lastDayOfMonth.getDate() }, (_, index) => {
    const date = new Date(firstDayOfMonth)
    date.setDate(date.getDate() + index)
    return date.toLocaleString('en-US', { month: 'short', day: '2-digit' })
  })
}

exports.getDaysInCurrentMonth = () => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()

  // To get the last day of the current month, set the day to 0 of the next month
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0)

  // The getDate() method returns the day of the month (1 through 31)
  const totalDaysInMonth = lastDayOfMonth.getDate()

  return totalDaysInMonth
}
exports.calculateCostPerDay = (amount, interval, intervalCount) => {
  const daysInMonth = this.getDaysInCurrentMonth() // Assuming an average of 30 days in a month

  const convertToDailyCost = (amount, interval) => {
    switch (interval) {
      case 'day':
        return amount
      case 'week':
        return amount / 7
      case 'month':
        return amount / daysInMonth
      case 'year':
        return amount / (daysInMonth * 12)
      default:
        return null
    }
  }

  const dailyCost = convertToDailyCost(amount, interval)
  const costPerDay = dailyCost * intervalCount

  return costPerDay
}

exports.calculateCostPerMonth = (amount, interval, intervalCount) => {
  switch (interval) {
    case 'day':
      return amount * 30
    case 'week':
      return amount / 7
    case 'month':
      const result = parseFloat(amount) / parseFloat(intervalCount)
      return Number.isInteger(result) ? result : result.toFixed(2)
    case 'year':
      return (amount / 12)?.toFixed(2)
    default:
      return null
  }
}

exports.getPricing = (item) => {
  return `$${Math.abs(item.amount)} every ${item.interval_count == 1 ? '' : item.interval_count} ${
    item.interval_count > 1 ? `${item.interval}s` : item.interval
  }`
}
exports.limitDecimalPlaces = (value, decimalPlaces) => {
  const stringValue = value?.toString()
  const decimalIndex = stringValue?.indexOf('.')

  if (decimalIndex !== -1) {
    // If there is a decimal point
    const decimalPart = stringValue?.substring(decimalIndex + 1, stringValue?.length)
    return decimalPart?.length > decimalPlaces ? parseFloat(stringValue)?.toFixed(decimalPlaces) : value
  }

  return value
}
exports.calculateNearestFutureSubscriptionEndDate = (startDate, period) => {
  // Convert start date string to a Date object
  const startDateObj = new Date(startDate)
  const currentDate = new Date()

  // Set time parts of startDateObj and currentDate to 0 to compare only date, month, and year
  startDateObj.setHours(0, 0, 0, 0)
  currentDate.setHours(0, 0, 0, 0)

  // If the start date is in the past, find the next future date
  if (startDateObj <= currentDate) {
    while (startDateObj <= currentDate) {
      // Define period mapping for each option
      const periodMap = {
        'every day': (date) => date.setDate(date.getDate() + 1),
        'every week': (date) => date.setDate(date.getDate() + 7),
        'every month': (date) => date.setMonth(date.getMonth() + 1),
        'every year': (date) => date.setFullYear(date.getFullYear() + 1),
        monthly: (date) => date.setMonth(date.getMonth() + 1),
        weekly: (date) => date.setDate(date.getDate() + 7),
        daily: (date) => date.setDate(date.getDate() + 1),
        annually: (date) => date.setFullYear(date.getFullYear() + 1),
        yearly: (date) => date.setFullYear(date.getFullYear() + 1),
        '6 months': (date) => date.setMonth(date.getMonth() + 6),
        quarterly: (date) => date.setMonth(date.getMonth() + 3),
      }

      // Check if the provided period is in the predefined periodMap
      if (period in periodMap) {
        periodMap[period](startDateObj)
      } else {
        const [frequency, unit] = period?.split(' ').filter((part) => part !== 'every')
        // Adjust the start date based on the period
        const unitWithoutS = unit.endsWith('s') ? unit.slice(0, -1) : unit

        let finalFrequency = frequency == '' ? 1 : frequency
        switch (unitWithoutS) {
          case 'day':
            startDateObj.setDate(startDateObj.getDate() + parseInt(finalFrequency))
            break
          case 'week':
            startDateObj.setDate(startDateObj.getDate() + parseInt(finalFrequency) * 7)
            break
          case 'month':
            startDateObj.setMonth(startDateObj.getMonth() + parseInt(finalFrequency))
            break
          case 'year':
            startDateObj.setFullYear(startDateObj.getFullYear() + parseInt(finalFrequency))
            break
          default:
            return 'Invalid period'
        }
      }
    }
  }

  return this.getFormattedMediumDate(startDateObj)
}

exports.setTimeFromDateStr = (timeStr) => {
  const currentTime = new Date() // Current date and time
  const dateWithNoTime = new Date(currentTime.setHours(0, 0, 0, 0)) // Reset hours, minutes, seconds, and milliseconds

  const [time, modifier] = timeStr?.split(' ')
  let [hours, minutes] = time?.split(':')

  // Convert hours to 24-hour format based on AM/PM
  if (hours === '12') {
    hours = '0'
  }
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12
  }

  dateWithNoTime.setHours(hours)
  dateWithNoTime.setMinutes(minutes)

  return dateWithNoTime
}
exports.setTimeFromDateStrForDate = (date, timeStr) => {
  const currentTime = new Date(date) // Current date and time
  const dateWithNoTime = new Date(currentTime.setHours(0, 0, 0, 0)) // Reset hours, minutes, seconds, and milliseconds

  const [time, modifier] = timeStr?.split(' ')
  let [hours, minutes] = time?.split(':')

  // Convert hours to 24-hour format based on AM/PM
  if (hours === '12') {
    hours = '0'
  }
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12
  }

  dateWithNoTime.setHours(hours)
  dateWithNoTime.setMinutes(minutes)

  return dateWithNoTime
}
exports.calculateEventStyles = (event, person, index, personEvents, locationStartTime) => {
  const start = new Date(event.start)
  const end = new Date(event.end)

  // Calculate the difference in minutes between start and end
  const durationInMinutes = (end.getTime() - start.getTime()) / (1000 * 60)

  // Calculate the difference in hours between event start time and location start time
  const startHourDifference = (start.getHours() - locationStartTime.getHours() + 1) * 60

  // Convert the start time to minutes
  const startHour = start.getHours()
  const startMinute = start.getMinutes()

  // Calculate the top position in pixels considering the location start time
  let top = startHourDifference + startMinute + 'px'
  if (event.allDay && index > 0) {
    top = '20px'
  } else if (event.allDay && index === 0) {
    top = 0 + 'px'
  }

  // Calculate the height based on the duration in minutes
  const height = durationInMinutes + 'px'

  // Calculate the width of the event
  let width = '100%'

  // Calculate z-index based on start time (higher z-index for later start times)
  const zIndex = 1000 + startHour * 60 + startMinute

  // Filter out events for the person that have already been rendered
  const renderedEvents = personEvents[person].slice(0, index)

  // Check for overlapping events among rendered events
  const overlappingEvents = renderedEvents.filter(
    (otherEvent) => otherEvent._id !== event._id && new Date(otherEvent.start) < new Date(event.end) && new Date(otherEvent.end) > new Date(event.start)
  )

  // If there are overlapping events, adjust the position and width
  if (!event.isBreak && !event.allDay && overlappingEvents.length > 0) {
    const left = (100 / (overlappingEvents.length + 1)) * overlappingEvents.length + '%'
    width = 100 / (overlappingEvents.length + 1) + '%'
    return {
      top,
      left,
      width,
      height,
      minHeight: 15,
      backgroundColor: statusColors[event.status]?.bgColor || '#ccc',
      position: 'absolute',
      boxSizing: 'border-box',
      borderLeft: `4px solid ${statusColors[event.status]?.textColor || '#000'}`,
      borderRadius: 6,
      color: statusColors[event.status]?.textColor || '#000',
      fontSize: 12,
      display: 'flex',
      alignItems: 'start',
      paddingTop: '2px',
      cursor: 'pointer',
      zIndex,
    }
  }

  // If there are no overlapping events, use regular styling
  return {
    margin: '1px 0',
    overflow: 'hidden',
    top,
    height,
    minHeight: 15,
    backgroundColor: statusColors[event.status]?.bgColor || '#ccc',
    position: 'absolute',
    width,
    boxSizing: 'border-box',
    borderLeft: event.isBreak ? '' : `4px solid ${statusColors[event.status]?.textColor || '#000'}`,
    borderRadius: 6,
    color: statusColors[event.status]?.textColor || '#000',
    fontSize: 12,
    display: 'flex',
    alignItems: 'start',
    paddingTop: '2px',
    cursor: 'pointer',
    zIndex,
  }
}
exports.calculateEmployeeFreeEventStyles = (event, locationStartTime) => {
  const start = this.setTimeFromDateStr(event.start)
  const end = this.setTimeFromDateStr(event.end)

  // Calculate the difference in minutes between start and end
  const durationInMinutes = (end.getTime() - start.getTime()) / (1000 * 60)

  // Calculate the difference in hours between event start time and location start time
  const startHourDifference = start.getHours() - locationStartTime.getHours() + 1 // Change from 60 to 240

  // Convert the start time to minutes
  const startHour = start.getHours()
  const startMinute = start.getMinutes()

  // Calculate the top position in pixels considering the location start time
  let top = '0px'
  top = startMinute + 'px'

  // Calculate the height based on the duration in minutes
  const height = (durationInMinutes / 60) * 60 + 'px'

  // Calculate the width of the event
  let width = '98%'

  // Calculate z-index based on start time (higher z-index for later start times)
  const zIndex = 1000 + startHour * 60 + startMinute

  // If there are no overlapping events, use regular styling
  return {
    overflow: 'hidden',
    top,
    height,
    backgroundColor: '#ebebeb',
    width,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'start',
    cursor: 'pointer',
    position: 'absolute',
  }
}

exports.timeSince = (timeStamp) => {
  const now = new Date()
  const secondsPast = (now.getTime() - timeStamp) / 1000
  if (secondsPast < 60) {
    return `${Math.round(secondsPast)}s ago`
  }
  if (secondsPast < 3600) {
    return `${Math.round(secondsPast / 60)}m ago`
  }
  if (secondsPast <= 86400) {
    return `${Math.round(secondsPast / 3600)}h ago`
  }
  if (secondsPast > 86400) {
    const day = timeStamp.getDate()
    const month = timeStamp
      .toDateString()
      .match(/ [a-zA-Z]*/)[0]
      .replace(' ', '')
    const year = timeStamp.getFullYear() === now.getFullYear() ? '' : ` ${timeStamp.getFullYear()}`
    return `${day} ${month}${year}`
  }
}
exports.convertMonthNumberToText = (monthNumber) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  return months[monthNumber - 1] || ''
}
exports.getBackgroundColorsBasedOnstatus = (status) => {
  const baseStyle = {
    padding: '2px',
    borderRadius: '4px',
  }

  const statusStyles = {
    Complete: {
      background: 'rgb(0 128 0 / 15%)',
      color: 'green',
    },
    'In Progress': {
      background: 'rgb(0 123 176 / 8%)',
      color: '#007bb0',
    },
    Arrived: {
      background: '#ffffff30',
      color: '#007bb0',
      border: '1.5px dashed  #007bb0',
    },
    'No Show': {
      background: 'rgb(249 60 46 / 14%)',
      color: '#f93c2e',
    },
    Upcoming: {
      background: '#6d6d6d40',
      color: '#4b4b4b',
    },
    Scheduled: {
      backgroundColor: 'rgb(255 165 0 / 17%)',
      color: 'orange',
    },
    Cancelled: {
      background: 'rgb(226 226 226 / 40%)',
      color: '#6d6d6d',
      border: '1.5px dashed  #6d6d6d',
    },
    Busy: {
      background: '#0018c733',
      color: '#0018c7',
    },
    Break: {
      backgroundImage: 'repeating-linear-gradient(130deg, rgb(238, 238, 238) 9px, rgb(238, 238, 238) 10px, #6a6eff4f 12px, #6a6eff4f  18px)',
      color: '#0018c7',
    },
    'Late/Early': {
      backgroundImage: 'repeating-linear-gradient(130deg, rgb(238, 238, 238) 9px, rgb(238, 238, 238) 10px, #bfbdbd99 12px, #bfbdbd99 18px)',
      color: '#545454',
    },
    'Closed/OOO': {
      background: '#d4d6d48f',
      color: 'white',
    },
  }

  return { ...baseStyle, ...statusStyles[status] }
}

exports.formatDateWithWeekDay = (date) => {
  const options = { weekday: 'short', month: 'long', day: 'numeric' }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}
exports.capitalizeFirstLetter = (str) => {
  if (!str) {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}
exports.formatDateToISOString = (date) => {
  const padToTwoDigits = (num) => String(num).padStart(2, '0')

  // Format the date as 'YYYY-MM-DDTHH:mm:ss'
  const formattedDateWithTime = `${date.getFullYear()}-${padToTwoDigits(date.getMonth() + 1)}-${padToTwoDigits(date.getDate())}T${padToTwoDigits(
    date.getHours()
  )}:${padToTwoDigits(date.getMinutes())}:${padToTwoDigits(date.getSeconds())}`
  return formattedDateWithTime
}
