import { IonGrid, IonInput, IonItem, IonLabel, IonRow, IonTextarea } from '@ionic/react'
import { fontSizes, fontWeights } from '../../theme/typography'
import commonStyles from '../../theme/commonstyles.module.css'
import { useContext } from 'react'
import { colors } from '../../theme/colors'
import { AuthContext } from '../../auth'
import PreviewMemberships from './PreviewMemberships'
import PreviewDuration from './PreviewDuration'
import PreviewProfessionals from './PreviewProfessionals'
export default function StepPreview({
  name,
  setName,
  description,
  setDescription,
  price,
  setPrice,
  durationMin,
  setDurationMin,
  selectedTime,
  setSelectedTime,
  selectedPackages,
  subscriptionPackages,
  packagesLoading,
  isIncludedInMembership,
  setIsIncludedInMembership,
  setSelectedPackages,
  employees,
  selectedEmployees,
  setSelectedEmployees,
  employeesLoading,
}) {
  const { businessData } = useContext(AuthContext)

  return (
    <>
      <IonGrid>
        <IonRow style={{ marginBottom: 10 }}>
          <IonLabel
            style={{
              alignSelf: 'stretch',
              fontSize: fontSizes.size32,
              fontWeight: fontWeights.weight600,
              lineHeight: '38.4px',
              wordWrap: 'break-word',
            }}
          >
            Almost done! Let’s make sure everything looks right.
          </IonLabel>
        </IonRow>
        <IonRow style={{ width: '100%', marginTop: 30 }}>
          <IonItem className={commonStyles.inputRoundedStyleNoPadding} style={{ width: '100%' }}>
            <IonInput
              label='Title'
              labelPlacement='stacked'
              value={name}
              onIonInput={(e) => setName(e.detail.value)}
              type='text'
              placeholder='Enter the service name'
            ></IonInput>
          </IonItem>{' '}
        </IonRow>
        <PreviewDuration durationMin={durationMin} setDurationMin={setDurationMin} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
        <IonRow style={{ width: '100%', marginTop: 10 }}>
          <IonItem className={commonStyles.inputRoundedStyleNoPadding} style={{ width: '100%' }}>
            <IonInput
              label='Price'
              labelPlacement='stacked'
              value={price}
              onIonInput={(e) => setPrice(e.detail.value)}
              type='text'
              placeholder='Enter price'
            ></IonInput>
          </IonItem>{' '}
        </IonRow>
        <IonRow style={{ width: '100%', marginTop: 10 }}>
          <IonTextarea
            label='Description (Optional)'
            labelPlacement='stacked'
            rows={5}
            onIonInput={(e) => setDescription(e.detail.value)}
            style={{ width: '100%' }}
            placeholder='Regular haircut, wash and a hot lather straight razor neck shave. Plus, a beard trim.'
            className={commonStyles.inputTextAreaStyle}
            value={description}
          ></IonTextarea>
        </IonRow>
      </IonGrid>
      <div style={{ height: 5, backgroundColor: colors.black5, marginTop: 20 }}></div>
      <PreviewMemberships
        name={name}
        packagesLoading={packagesLoading}
        subscriptionPackages={subscriptionPackages}
        selectedPackages={selectedPackages}
        isIncludedInMembership={isIncludedInMembership}
        setSelectedPackages={setSelectedPackages}
        setIsIncludedInMembership={setIsIncludedInMembership}
      />
      <div style={{ height: 5, backgroundColor: colors.black5, marginTop: 20 }}></div>
      <PreviewProfessionals
        setSelectedEmployees={setSelectedEmployees}
        employeesLoading={employeesLoading}
        employees={employees}
        selectedEmployees={selectedEmployees}
      />
    </>
  )
}
