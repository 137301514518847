import React, { useState, useContext } from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonButtons,
  IonTextarea,
  IonIcon
} from '@ionic/react';
import { close, calendar, person } from 'ionicons/icons';
import axios from 'axios';
import { AuthContext } from '../auth';

const modalStyles = {
  modal: {
    '--height': '90%',
    '--border-radius': '20px 20px 0 0'
  },
  header: {
    '--background': 'transparent',
    '--border-style': 'none'
  },
  closeButton: {
    margin: '8px',
    borderRadius: '50%',
    // padding: '8px',
    backgroundColor: '#f7f7f7',
    width: '32px',
    height: '32px',
  },
  title: {
    fontSize: '24px',
    fontWeight: '700',
    padding: '16px 0',
    textAlign: 'center'
  },
  list: {
    background: 'transparent',
    padding: '0 16px'
  },
  item: {
    '--background': 'transparent',
    '--border-color': '#e0e0e0',
    '--padding-start': '0',
    '--padding-end': '0',
    '--inner-padding-end': '0',
    '--background-hover': 'transparent',
    '--background-hover-opacity': 0,
    '--ripple-color': 'transparent'
  },
  label: {
    fontWeight: '500',
    color: '#222222',
    margin: '8px 0',
    '--background': 'transparent',
    '--background-hover': 'transparent',
    '--background-hover-opacity': 0
  },
  input: {
    '--background': '#f7f7f7',
    '--padding-start': '16px',
    '--padding-end': '16px',
    '--padding-top': '12px',
    '--padding-bottom': '12px',
    '--border-radius': '12px',
    marginTop: '8px',
    marginBottom: '16px'
  },
  select: {
    '--padding-start': '16px',
    '--padding-end': '16px',
    '--padding-top': '12px',
    '--padding-bottom': '12px',
    '--border-radius': '12px',
    backgroundColor: '#f7f7f7',
    marginTop: '8px',
    marginBottom: '16px'
  },
  submitButton: {
    margin: '24px 16px',
    '--border-radius': '12px',
    '--padding-top': '16px',
    '--padding-bottom': '16px',
    fontSize: '16px',
    fontWeight: '600'
  },
  dateInput: {
    '--background': '#f7f7f7',
    '--padding-start': '16px',
    '--padding-end': '16px',
    '--padding-top': '12px',
    '--padding-bottom': '12px',
    '--border-radius': '12px',
    '--border-width': '0',
    '--inner-border-width': '0',
    '--border-style': 'none',
    '--highlight-height': '0',
    marginTop: '8px',
    marginBottom: '16px',
    backgroundColor: '#f7f7f7',
    cursor: 'pointer',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    borderRadius: '12px',
    overflow: 'hidden',
    width: '100%'
  },
  dateText: {
    fontSize: '16px',
    color: 'var(--ion-text-color)'
  },
  datePickerContainer: {
    backgroundColor: '#f7f7f7',
    borderRadius: '12px',
    overflow: 'hidden',
    marginTop: '-16px',  // Adjust to connect with input
    marginBottom: '16px'
  },
  datePicker: {
    margin: 0,
    padding: '8px 0',
    width: '100%',
    maxWidth: '100%',
    '--background': 'transparent'
  },
  userEmailItem: {
    '--background': '#f7f7f7',
    '--padding-start': '16px',
    '--padding-end': '16px',
    '--padding-top': '10px',
    '--padding-bottom': '10px',
    '--border-radius': '12px',
    '--border-width': '0',
    '--inner-border-width': '0',
    '--border-style': 'none',
    '--highlight-height': '0',
    marginTop: '8px',
    marginBottom: '16px',
    backgroundColor: '#f7f7f7',
    borderRadius: '12px',
    overflow: 'hidden',
    width: '100%',
    opacity: 0.7
  },
  userEmailText: {
    fontSize: '16px',
    color: 'var(--ion-text-color)',
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  }
};

const getTypeColor = (type) => {
  switch (type) {
    case 'Cash In':
      return 'var(--ion-color-success)';
    case 'Cash Out':
      return 'var(--ion-color-danger)';
    case 'Audit':
      return 'var(--ion-color-primary)';
    default:
      return 'var(--ion-color-primary)';
  }
};

const AddEntryModal = ({ isOpen, onClose, onSuccess }) => {
  const { userData } = useContext(AuthContext);
  const [type, setType] = useState('Cash In');
  const [value, setValue] = useState('');
  const [note, setNote] = useState('');
  const [date, setDate] = useState(new Date().toISOString());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const formatDisplayDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const handleSubmit = async () => {
    if (!value || !type) {
      setError('Please fill in all required fields');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await axios.post('/cashManagement', {
        type,
        value: parseFloat(value),
        note: note || '',
        date: new Date(date)
      });

      // Reset form
      setType('Cash In');
      setValue('');
      setNote('');
      setDate(new Date().toISOString());
      
      onSuccess();
      onClose();
    } catch (err) {
      setError('Failed to add entry');
    } finally {
      setLoading(false);
    }
  };

  const getButtonText = () => {
    if (loading) return 'Adding...';
    if (!value) return 'Add Entry';
    
    const amount = parseFloat(value).toFixed(2);
    if (type === 'Audit') {
      return `${type} - $${amount}`;
    }
    return `${type} - ${type === 'Cash In' ? '+' : '-'}$${amount}`;
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} style={modalStyles.modal}>
      <IonHeader style={modalStyles.header}>
        <IonToolbar style={modalStyles.header}>
          <IonButtons slot="start">
            <IonButton 
              onClick={onClose}
              style={modalStyles.closeButton}
            >
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
          <IonTitle style={modalStyles.title}>Add Cash Entry</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList style={modalStyles.list}>
          <IonItem style={modalStyles.item} lines="none">
            <IonLabel position="stacked" style={modalStyles.label}>
              User
            </IonLabel>
            <IonItem 
              lines="none" 
              style={modalStyles.userEmailItem}
              detail={false}
            >
              <div style={modalStyles.userEmailText}>
                <IonIcon icon={person} />
                <span>{userData?.email}</span>
              </div>
            </IonItem>
          </IonItem>

          <IonItem style={modalStyles.item} lines="none">
            <IonLabel position="stacked" style={modalStyles.label}>
              Type
            </IonLabel>
            <IonSelect 
              value={type} 
              onIonChange={e => setType(e.detail.value)}
              interface="popover"
              style={modalStyles.select}
            >
              <IonSelectOption value="Cash In">Cash In</IonSelectOption>
              <IonSelectOption value="Cash Out">Cash Out</IonSelectOption>
              <IonSelectOption value="Audit">Audit</IonSelectOption>
            </IonSelect>
          </IonItem>

          <IonItem style={modalStyles.item} lines="none">
            <IonLabel position="stacked" style={modalStyles.label}>
              Amount ($)
            </IonLabel>
            <IonInput
              type="number"
              value={value}
              onIonChange={e => setValue(e.detail.value)}
              placeholder="0.00"
              required
              style={modalStyles.input}
            />
          </IonItem>

          <IonItem style={modalStyles.item} lines="none">
            <IonLabel position="stacked" style={modalStyles.label}>
              Note
            </IonLabel>
            <IonTextarea
              value={note}
              onIonChange={e => setNote(e.detail.value)}
              placeholder="Add a note..."
              rows={3}
              style={modalStyles.input}
            />
          </IonItem>

          <IonItem style={modalStyles.item} lines="none">
            <IonLabel position="stacked" style={modalStyles.label}>
              Date
            </IonLabel>
            <IonItem 
              lines="none" 
              style={modalStyles.dateInput}
              onClick={() => setShowDatePicker(!showDatePicker)}
              detail={false}
            >
              <span style={modalStyles.dateText}>{formatDisplayDate(date)}</span>
              <IonIcon 
                icon={calendar} 
                slot="end"
                style={{ 
                  color: 'var(--ion-color-medium)',
                  transform: showDatePicker ? 'rotate(360deg)' : 'none',
                  transition: 'transform 0.2s ease',
                  marginLeft: '20px',
                  marginRight: '-10px'
                }} 
              />
            </IonItem>
            {showDatePicker && (
              <div style={modalStyles.datePickerContainer}>
                <IonDatetime
                  value={date}
                  onIonChange={e => {
                    setDate(e.detail.value);
                  }}
                  presentation="date"
                  style={modalStyles.datePicker}
                />
              </div>
            )}
          </IonItem>
        </IonList>

        {error && (
          <div style={{ 
            padding: '16px', 
            color: 'var(--ion-color-danger)',
            textAlign: 'center',
            fontWeight: '500'
          }}>
            {error}
          </div>
        )}

        <IonButton 
          expand="block" 
          onClick={handleSubmit}
          disabled={loading}
          style={{
            ...modalStyles.submitButton,
            '--background': getTypeColor(type)
          }}
        >
          {getButtonText()}
        </IonButton>
      </IonContent>
    </IonModal>
  );
};

export default AddEntryModal; 