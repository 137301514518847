import { IonCheckbox, IonGrid, IonItem, IonLabel, IonList, IonRow, IonSpinner } from '@ionic/react'
import { useContext } from 'react'
import { fontSizes, fontWeights } from '../../theme/typography'
import { colors } from '../../theme/colors'
import { AuthContext } from '../../auth'
import Avatar from '../../commonComponents/avatar'

export default function StepProfessionals({ employeesLoading, employees, selectedEmployees, setSelectedEmployees }) {
  const { businessData, locationId } = useContext(AuthContext)
  const handleEmployeeSelect = (id) => {
    let selected = [...selectedEmployees]
    // Check if employee already exists in the array
    if (selected.includes(id)) {
      // Remove employee from the array
      selected = selected.filter((emp) => emp !== id)
    } else {
      // Add employee to the array
      selected = [...selected, id]
    }

    setSelectedEmployees(selected)
  }

  return (
    <IonGrid>
      <IonRow>
        <IonLabel
          style={{
            fontSize: fontSizes.size28,
            fontWeight: fontWeights.weight600,
            lineHeight: '33.6px',
            wordWrap: 'break-word',
            marginBottom: fontSizes.size16,
          }}
        >
          Who can perform Zero Fade Haircut?
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonLabel
          style={{
            marginBottom: fontSizes.size16,
            color: colors.black80,
            fontSize: fontSizes.size18,
            lineHeight: '28px',
          }}
        >
          Customers will be able to book an appointment with the selected professionals.
        </IonLabel>
      </IonRow>
      {employeesLoading ? (
        <IonSpinner name='dots' />
      ) : (
        <IonList>
          {employees?.map((item, index) => {
            return (
              <IonItem onClick={() => handleEmployeeSelect(item?._id)} key={item?._id}>
                <IonCheckbox
                  slot='end'
                  checked={selectedEmployees.includes(item?._id)}
                  onIonChange={() => handleEmployeeSelect(item?._id)} // Handle checkbox change
                />
                <div style={{ marginRight: 20 }}>
                  <Avatar employee={item} businessId={businessData?._id} small />
                </div>
                <IonLabel style={{ padding: '16px 0' }}>
                  {item.firstName} {item.lastName}
                </IonLabel>
              </IonItem>
            )
          })}
        </IonList>
      )}
    </IonGrid>
  )
}
