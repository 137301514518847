import FinalizePaymentAndTip from './FinalizePaymentAndTip'
import ExternalPayment from './ExternalPayment'
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonModal, IonToast } from '@ionic/react'
import { cardOutline, cashOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { calculatePayment } from '../../../sharedComponents/paymentCalculation'
import { useContext } from 'react'
import { AuthContext } from '../../../auth'

export default function PaymentOptions({ appointment, getAppointment, subscription, paymentOptionsModalOpen, setPaymentOptionsModalOpen }) {
  const [finalizePaymentModalOpen, setFinalizePaymentModalOpen] = useState(false)
  const [payExternalModalOpen, setPayExternalModalOpen] = useState(false)
  const [tip, setTip] = useState()
  const [payment, setPayment] = useState([])
  const [updating, setUpdating] = useState(false)
  const [toast, setToast] = useState({
    isOpen: false,
    message: '',
    color: 'success', // default color
  })
  const { selectedLocationData } = useContext(AuthContext)
  const payedExternal = async () => {
    if (window.confirm('Has the customer paid through other ways than this app? This should be uncommon. Click "Ok" to continue')) {
      setUpdating(true)
      try {
        let response = await axios.patch(`/appointment_v2/updateStatus`, {
          appointmentId: appointment?._id,
          status: 'Complete',
          paidExternal: true,
        })
        await getAppointment()
      } catch (error) {
        setToast({ isOpen: true, message: 'Error updating appointment status.', color: 'danger' })
      }
      setUpdating(false)
      setFinalizePaymentModalOpen(false)
      setPaymentOptionsModalOpen(false)
      setPayExternalModalOpen(false)
    }
  }

  useEffect(() => {
    // if (appointment && appointment.status != 'Complete') {
      setPayment(
        calculatePayment({
          services: appointment?.services,
          products: appointment?.products,
          discounts: appointment?.discounts,
          subscriptionPackageId: subscription?.data?.subscription?.subscriptionPackageId,
          location: selectedLocationData,
          tipAmount: tip || appointment?.payment?.tipAmount,
          tipId: tip?.id || appointment?.payment?.tipId,
          cashTipAmount: appointment?.payment?.cashTipAmount || 0,
          options: {
            percentDiscountOnOtherServices: subscription?.data?.subscription?.percentDiscountOnOtherServices,
            percentDiscountOnProduct: subscription?.data?.subscription?.percentDiscountOnProduct,
          }
        })
      )
    // } else if (appointment) {
    //   setPayment(appointment?.payment)
    // }
  }, [subscription, appointment, tip])
  return (
    <>
      <IonModal
        isOpen={paymentOptionsModalOpen}
        onDidDismiss={() => {
          setPaymentOptionsModalOpen(false)
          setPayExternalModalOpen(false)
        }}
        trigger='open-modal'
        initialBreakpoint={0.75}
        breakpoints={[0.75, 1]}
        cssClass='custom-modal'
      >
        <IonContent>
          <div style={{ padding: '1rem' }}>
            <h2>Payment Options</h2>
            <IonList mode='ios' inset={true}>
              <IonItem onClick={() => setFinalizePaymentModalOpen(true)} detail={true}>
                <IonIcon icon={cardOutline} slot='start' />
                <IonLabel>Pay with card</IonLabel>
              </IonItem>
              <IonItem onClick={() => setPayExternalModalOpen(true)} detail={true}>
                <IonIcon icon={cashOutline} slot='start' />
                <IonLabel>Pay external</IonLabel>
              </IonItem>
            </IonList>
          </div>
        </IonContent>
      </IonModal>
      { }
      <FinalizePaymentAndTip
        setPaymentOptionsModalOpen={setPaymentOptionsModalOpen}
        isOpen={finalizePaymentModalOpen}
        setOpen={setFinalizePaymentModalOpen}
        appointment={appointment}
        updateAppointment={getAppointment}
        tip={tip}
        setTip={setTip}
        payment={payment}
      />
      <ExternalPayment
        payment={payment}
        payExternalModalOpen={payExternalModalOpen}
        setPayExternalModalOpen={setPayExternalModalOpen}
        updating={updating}
        appointment={appointment}
        payedExternal={payedExternal}
      />
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        message={toast.message}
        duration={2000}
        color={toast.color}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
      />
    </>
  )
}
