import { IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonItem, IonLabel, IonList, IonModal, IonRow, IonText, IonIcon, IonSpinner } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { calculateCostPerMonth, getPricing } from '../utils'
import axios from 'axios'
import { refreshOutline, arrowBack } from 'ionicons/icons'
import MigrationPlanSelector from './MigrationPlanSelector'
import { AuthContext } from '../auth'
import { useContext } from 'react'

export default function MigrationModal({ isOpen, closeModal, option, subscriptionPlanName, refreshSubscriptionCounts }) {
  const { businessData } = useContext(AuthContext)
  const [currentScreen, setCurrentScreen] = useState(1)
  const [subscriptionPackages, setSubscriptionPackages] = useState({
    loading: false,
    data: null,
    error: null,
  })
  const [selectedPackage, setSelectedPackage] = useState(false)
  const [selectedPrice, setSelectedPrice] = useState(false)
  const [subscribedCustomers, setSubscribedCustomers] = useState([])
  const [selectedCustomers, setSelectedCustomers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isMigrating, setIsMigrating] = useState(false)
  const [migrationResults, setMigrationResults] = useState(null)

  useEffect(() => {
    console.log('Current screen:', currentScreen)
  }, [currentScreen])

  useEffect(() => {
    if (isOpen && option) {
      fetchSubscribedCustomers(option.stripePriceId)
    }
  }, [isOpen, option])

  useEffect(() => {
    console.log('MigrationModal props:', {
      option,
      subscriptionPlanName
    })
  }, [option, subscriptionPlanName])

  const fetchSubscribedCustomers = async (stripePriceId) => {
    setIsLoading(true)
    try {
      const { data } = await axios.get(`/subscription/listForMigration`, {
        params: {
          stripePriceId
        }
      })
      
      const customersArray = Array.isArray(data) ? data : data.customers || []
      setSubscribedCustomers(customersArray)
      setSelectedCustomers(customersArray.map(customer => customer.customerId))
    } catch (error) {
      console.error('Error fetching subscribed customers:', error)
      setSubscribedCustomers([])
      setSelectedCustomers([])
    } finally {
      setIsLoading(false)
    }
  }

  const handleCustomerSelection = (customerId) => {
    console.log('handleCustomerSelection', customerId)
    setSelectedCustomers(prev => {
      const isCurrentlySelected = prev.includes(customerId)
      if (isCurrentlySelected) {
        return prev.filter(id => id !== customerId)
      } else {
        return [...prev, customerId]
      }
    })
  }

  const handleSelectedPackage = (packageItem) => {
    setSelectedPackage(packageItem)
    setSelectedPrice(false)
  }

  const handleSelectedPrice = (price) => {
    setSelectedPrice(price)
  }

  useEffect(() => {
    if (currentScreen === 2) {
      getSubscriptionPackages()
    }
  }, [currentScreen])

  async function getSubscriptionPackages() {
    setSubscriptionPackages({ ...subscriptionPackages, ...{ loading: true } })
    try {
      let r = await axios.get(`/subscriptionPackage/getList?businessId=${businessData._id}`)
      setSubscriptionPackages({ loading: false, data: r.data, error: null })
    } catch (error) {
      setSubscriptionPackages({ ...subscriptionPackages, ...{ loading: false, error } })
    }
  }

  const resetModalState = () => {
    setCurrentScreen(1)
    setSubscriptionPackages({
      loading: false,
      data: null,
      error: null,
    })
    setSelectedPackage(false)
    setSelectedPrice(false)
    setSubscribedCustomers([])
    setSelectedCustomers([])
    setIsLoading(false)
    setIsMigrating(false)
    setMigrationResults(null)
  }

  const handleClose = async () => {
    resetModalState()
    closeModal()
    if (refreshSubscriptionCounts) {
      await refreshSubscriptionCounts()
    }
  }

  const migrateCustomers = async () => {
    setIsMigrating(true)
    try {
      const { data } = await axios.post('/subscription/bulkMigrate', {
        customers: selectedCustomers,
        fromPlan: {
          stripePriceId: option.stripePriceId,
          subscriptionPackageId: option.subscriptionPackage_id
        },
        toPlan: {
          stripePriceId: selectedPrice.stripePriceId,
          subscriptionId: selectedPackage._id,
          price: selectedPrice.amount,
          period: `Every ${selectedPrice.interval_count === 1 ? '' : selectedPrice.interval_count} ${
            selectedPrice.interval_count > 1 ? `${selectedPrice.interval}s` : selectedPrice.interval
          }`
        }
      })
      setMigrationResults(data)
      setIsMigrating(false)
    } catch (error) {
      console.error('Migration error:', error)
      setIsMigrating(false)
    }
  }

  return (
    <IonModal isOpen={isOpen}>
      <IonContent style={{ '--background': 'white' }}>
        <div style={{
          position: 'sticky',
          top: 0,
          background: 'white',
          zIndex: 100,
          padding: '16px',
          borderBottom: '1px solid #e0e0e0',
          display: 'flex',
          alignItems: 'center',
          gap: '16px'
        }}>
          {(currentScreen === 2 || currentScreen === 3) ? (
            <IonButton
              fill="clear"
              color="medium"
              onClick={() => setCurrentScreen(currentScreen - 1)}
              style={{
                margin: 0,
                '--padding-start': '4px',
                '--padding-end': '4px'
              }}
            >
              <IonIcon icon={arrowBack} slot="icon-only" style={{ fontSize: '24px' }} />
            </IonButton>
          ): <div style={{width:40}} />}
          <div style={{ flex: 1 }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              marginBottom: '4px'
            }}>
              <div style={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                background: currentScreen >= 1 ? '#FF385C' : '#e0e0e0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: '14px',
                fontWeight: '500'
              }}>1</div>
              <div style={{
                flex: 1,
                height: '2px',
                background: currentScreen >= 2 ? '#FF385C' : '#e0e0e0'
              }} />
              <div style={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                background: currentScreen >= 2 ? '#FF385C' : '#e0e0e0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: '14px',
                fontWeight: '500'
              }}>2</div>
              <div style={{
                flex: 1,
                height: '2px',
                background: currentScreen === 3 ? '#FF385C' : '#e0e0e0'
              }} />
              <div style={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                background: currentScreen === 3 ? '#FF385C' : '#e0e0e0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: '14px',
                fontWeight: '500'
              }}>3</div>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '12px',
              color: '#717171'
            }}>
              <span>Customers</span>
              <span style={{marginLeft:-14}}>Plan</span>
              <span>Confirm</span>
            </div>
          </div>
          <IonButton
            fill="clear"
            onClick={handleClose}
            style={{
              margin: 0,
              '--padding-start': '14px',
              '--padding-end': '14px',
              color: '#999999',
              fontSize: '24px'
            }}
          >
            ✕
          </IonButton>
        </div>

        {currentScreen === 1 ? (
          <div style={{ 
            padding: '24px 16px',
            maxWidth: '600px',
            margin: '0 auto',
            paddingBottom: '80px'
          }}>
            <div style={{
              marginBottom: '32px',
              textAlign: 'left'
            }}>
              <h1 style={{
                fontSize: '26px',
                fontWeight: '600',
                marginBottom: '8px',
                color: '#222222'
              }}>
                Select customers to update
              </h1>
              <IonText color="medium" style={{
                fontSize: '16px',
                lineHeight: '1.4'
              }}>
                Choose which customers you'd like to migrate from the{' '}
                <strong>{subscriptionPlanName} | {option?.name}</strong> option ({getPricing(option)})
                 to a new pricing plan.
              </IonText>
            </div>

            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '12px'
            }}>
              <div style={{
                color: '#FF385C',
                fontSize: '14px',
                fontWeight: '500'
              }}>
                {selectedCustomers.length} of {subscribedCustomers.length} selected
              </div>
              <IonButton
                fill="clear"
                onClick={() => {
                  if (selectedCustomers.length === subscribedCustomers.length) {
                    // Deselect all
                    setSelectedCustomers([])
                  } else {
                    // Select all
                    setSelectedCustomers(subscribedCustomers.map(c => c.customerId))
                  }
                }}
                style={{
                  margin: 0,
                  '--color': '#FF385C',
                  fontSize: '14px'
                }}
              >
                {selectedCustomers.length === subscribedCustomers.length ? 'Deselect All' : 'Select All'}
              </IonButton>
            </div>

            <div style={{
              background: '#fff',
              borderRadius: '12px',
              boxShadow: '0 1px 2px rgba(0,0,0,0.08), 0 4px 12px rgba(0,0,0,0.05)'
            }}>
              {isLoading ? (
                <div style={{ padding: '24px', textAlign: 'center' }}>
                  <IonText color="medium">Loading customers...</IonText>
                </div>
              ) : subscribedCustomers.length === 0 ? (
                <div style={{ 
                  padding: '32px 24px', 
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '16px'
                }}>
                  <IonText color="medium">No customers found</IonText>
                  <IonButton
                    fill="clear"
                    onClick={() => fetchSubscribedCustomers(option.stripePriceId)}
                    style={{
                      '--color': '#FF385C',
                      '--color-activated': '#E31C5F',
                    }}
                  >
                    <IonIcon icon={refreshOutline} slot="start" />
                    Try again
                  </IonButton>
                </div>
              ) : (
                <IonList style={{ background: 'transparent', marginBottom: 50 }}>
                  {subscribedCustomers.map(customer => (
                    <IonItem 
                      key={customer.customerId}
                      onClick={() => handleCustomerSelection(customer.customerId)}
                      button
                      style={{
                        '--background': 'transparent',
                        '--border-color': '#e0e0e0',
                        '--padding-start': '16px',
                        '--padding-end': '16px',
                        '--min-height': '64px',
                        cursor: 'pointer'
                      }}
                    >
                      <IonCheckbox
                        slot="start"
                        mode="md"
                        checked={selectedCustomers.includes(customer.customerId)}
                        style={{
                          '--size': '20px',
                          '--checkbox-background-checked': '#FF385C',
                          '--border-color': '#717171',
                          '--border-color-checked': '#FF385C',
                          pointerEvents: 'none'
                        }}
                      />
                      <div style={{ marginLeft: '12px' }}>
                        <IonText style={{
                          fontWeight: '500',
                          color: '#222222',
                          display: 'block',
                          marginBottom: '4px'
                        }}>
                          {customer.firstName ? customer.firstName + ' ' + customer.lastName : 'Unnamed Customer'}
                        </IonText>
                        <IonText color="medium" style={{
                          fontSize: '14px'
                        }}>
                          {customer.email}
                        </IonText>
                      </div>
                    </IonItem>
                  ))}
                </IonList>
              )}
            </div>

            <div style={{
              position: 'fixed',
              bottom: '0',
              left: '0',
              right: '0',
              background: 'linear-gradient(to top, white 80%, rgba(255,255,255,0.8) 95%, rgba(255,255,255,0) 100%)',
              padding: '24px 16px',
              zIndex: 100,
              display: 'flex',
              justifyContent: 'center'
            }}>
              <div style={{ maxWidth: '600px', width: '100%' }}>
                <IonButton
                  expand="block"
                  style={{
                    '--background': '#FF385C',
                    '--background-activated': '#E31C5F',
                    '--border-radius': '8px',
                    '--box-shadow': 'none',
                    height: '48px',
                    margin: 0,
                    fontWeight: '500'
                  }}
                  onClick={() => setCurrentScreen(2)}
                >
                  Next ({selectedCustomers.length} selected)
                </IonButton>
              </div>
            </div>
          </div>
        ) : currentScreen === 2 ? (
          <div style={{ 
            padding: '24px 16px',
            maxWidth: '600px',
            margin: '0 auto',
            paddingBottom: '80px'
          }}>
            {console.log('Screen 2 rendering', {
              subscriptionPackages,
              selectedPackage,
              selectedPrice
            })}
            
            <div style={{
              marginBottom: '32px',
              textAlign: 'left'
            }}>
              <h1 style={{
                fontSize: '26px',
                fontWeight: '600',
                marginBottom: '8px',
                color: '#222222'
              }}>
                Choose New Plan
              </h1>
              <IonText color="medium" style={{
                fontSize: '16px',
                lineHeight: '1.4'
              }}>
                <strong>{selectedCustomers.length} customers </strong> will be moved from{' '}
                <strong>{subscriptionPlanName} | {option?.name}</strong> ({getPricing(option)}) to a new plan selected below.
                <br />
              </IonText>
            </div>

            <MigrationPlanSelector
              subscriptionPackages={subscriptionPackages}
              selectedPackage={selectedPackage}
              selectedPrice={selectedPrice}
              handleSelectedPrice={handleSelectedPrice}
              handleSelectedPackage={handleSelectedPackage}
            />

            {selectedPrice && (
              <div style={{
                position: 'fixed',
                bottom: '0',
                left: '0',
                right: '0',
                background: 'linear-gradient(to top, white 80%, rgba(255,255,255,0.8) 95%, rgba(255,255,255,0) 100%)',
                padding: '24px 16px',
                zIndex: 100,
                display: 'flex',
                justifyContent: 'center'
              }}>
                <div style={{ maxWidth: '600px', width: '100%' }}>
                  <IonButton
                    expand="block"
                    style={{
                      '--background': '#FF385C',
                      '--background-activated': '#E31C5F',
                      '--border-radius': '8px',
                      '--box-shadow': 'none',
                      height: '48px',
                      margin: 0,
                      fontWeight: '500'
                    }}
                    onClick={() => setCurrentScreen(3)}
                  >
                    Next
                  </IonButton>
                </div>
              </div>
            )}
          </div>
        ) : currentScreen === 3 ? (
          <div style={{ 
            padding: '24px 16px',
            maxWidth: '600px',
            margin: '0 auto',
            paddingBottom: '80px'
          }}>
            {isMigrating ? (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '400px',
                gap: '24px',
                textAlign: 'center'
              }}>
                <IonSpinner name="dots" style={{ 
                  '--color': '#FF385C',
                  transform: 'scale(1.5)'
                }} />
                <div>
                  <h2 style={{
                    fontSize: '24px',
                    fontWeight: '600',
                    marginBottom: '8px',
                    color: '#222222'
                  }}>
                    Migration in Progress
                  </h2>
                  <IonText color="medium" style={{
                    fontSize: '16px',
                    lineHeight: '1.4'
                  }}>
                    Moving {selectedCustomers.length} customer{selectedCustomers.length !== 1 ? 's' : ''} to the new plan.
                    <br />
                    This may take a few moments.
                  </IonText>
                </div>
              </div>
            ) : migrationResults ? (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '32px 24px',
                textAlign: 'center'
              }}>
                <div style={{ marginBottom: '32px' }}>
                  <h2 style={{
                    fontSize: '24px',
                    fontWeight: '600',
                    marginBottom: '8px',
                    color: '#222222'
                  }}>
                    Migration Complete
                  </h2>
                  <IonText color="medium" style={{
                    fontSize: '16px',
                    lineHeight: '1.4'
                  }}>
                    {migrationResults.totalProcessed} customers processed
                  </IonText>
                </div>

                <div style={{
                  width: '100%',
                  maxWidth: '400px',
                  marginBottom: '32px'
                }}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '24px',
                    marginBottom: '24px'
                  }}>
                    <div style={{ textAlign: 'center' }}>
                      <div style={{
                        fontSize: '32px',
                        fontWeight: '600',
                        color: '#00C853',
                        marginBottom: '4px'
                      }}>
                        {migrationResults.results.filter(r => r.success).length}
                      </div>
                      <IonText color="medium">Successful</IonText>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <div style={{
                        fontSize: '32px',
                        fontWeight: '600',
                        color: '#FF385C',
                        marginBottom: '4px'
                      }}>
                        {migrationResults.results.filter(r => !r.success).length}
                      </div>
                      <IonText color="medium">Failed</IonText>
                    </div>
                  </div>

                  {migrationResults.results.some(r => !r.success) && (
                    <div style={{
                      background: '#FFF8F6',
                      border: '1px solid #FFE4E6',
                      borderRadius: '8px',
                      padding: '16px',
                      marginTop: '16px'
                    }}>
                      <div style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        color: '#FF385C',
                        marginBottom: '8px'
                      }}>
                        Failed Migrations
                      </div>
                      {migrationResults.results
                        .filter(r => !r.success)
                        .map((result, index) => (
                          <div 
                            key={result.customerId}
                            style={{
                              fontSize: '14px',
                              color: '#666666',
                              marginBottom: index !== migrationResults.results.length - 1 ? '8px' : 0
                            }}
                          >
                            {subscribedCustomers.find(c => c.customerId === result.customerId)?.email}
                            <div style={{ fontSize: '12px', color: '#FF385C' }}>
                              {result.error}
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>

                <IonButton
                  expand="block"
                  onClick={handleClose}
                  style={{
                    '--background': '#FF385C',
                    '--background-activated': '#E31C5F',
                    '--border-radius': '8px',
                    '--box-shadow': 'none',
                    height: '48px',
                    margin: 0,
                    fontWeight: '500',
                    maxWidth: '200px'
                  }}
                >
                  Close
                </IonButton>
              </div>
            ) : (
              <>
                <div style={{
                  marginBottom: '32px',
                  textAlign: 'left'
                }}>
                  <h1 style={{
                    fontSize: '26px',
                    fontWeight: '600',
                    marginBottom: '8px',
                    color: '#222222'
                  }}>
                    Confirm Changes
                  </h1>
                  <IonText color="medium" style={{
                    fontSize: '16px',
                    lineHeight: '1.4'
                  }}>
                    Please review and confirm the subscription changes.
                  </IonText>
                </div>

                <div style={{
                  background: '#fff',
                  borderRadius: '12px',
                  boxShadow: '0 1px 2px rgba(0,0,0,0.08), 0 4px 12px rgba(0,0,0,0.05)',
                  padding: '24px',
                  marginBottom: '24px'
                }}>
                  <div style={{ marginBottom: '24px' }}>
                    <div style={{ 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '8px'
                    }}>
                      <h3 style={{ margin: 0 }}>Customers to Migrate</h3>
                      <IonButton
                        fill="clear"
                        onClick={() => setCurrentScreen(1)}
                        style={{
                          '--color': '#FF385C',
                          margin: 0
                        }}
                      >
                        Change
                      </IonButton>
                    </div>
                    <IonText color="dark" style={{ fontWeight: '500', fontSize: '24px' }}>
                      {selectedCustomers.length} customer{selectedCustomers.length !== 1 ? 's' : ''}
                    </IonText>
                  </div>

                  <div style={{
                    display: 'flex',
                    flexDirection: window.innerWidth < 600 ? 'column' : 'row',
                    alignItems: 'stretch',
                    gap: '24px',
                    position: 'relative',
                    paddingTop: '20px'
                  }}>
                    {/* Current Plan Box */}
                    <div style={{
                      flex: 1,
                      padding: '16px',
                      border: '1px solid #e0e0e0',
                      borderRadius: '8px',
                      background: 'white'
                    }}>
                      <IonText color="medium" style={{ 
                        fontSize: '12px',
                        display: 'block',
                        marginBottom: '8px'
                      }}>
                        Current Plan
                      </IonText>
                      <div style={{ marginBottom: '8px' }}>
                        <IonText color="dark" style={{ fontWeight: '500' }}>
                          {option?.subscriptionPackage?.name || subscriptionPlanName} - {option?.name}
                        </IonText>
                      </div>
                      <IonText color="medium">
                        {getPricing(option)}
                      </IonText>
                    </div>

                    {/* Arrow/Connection */}
                    <div style={{
                      position: 'absolute',
                      ...(window.innerWidth < 600 
                        ? { left: '0', top: '50%', transform: 'translateY(-50%)' }
                        : { top: '0', left: '50%', transform: 'translateX(-50%)' }
                      ),
                      color: '#717171',
                      fontSize: '12px',
                      background: 'white',
                      padding: '0 8px',
                      zIndex: 2
                    }}>
                      Migrating to
                    </div>
                    <div style={{
                      width: window.innerWidth < 600 ? 'auto' : '40px',
                      height: window.innerWidth < 600 ? '40px' : 'auto',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                      margin: window.innerWidth < 600 ? '-12px 0' : '0'
                    }}>
                      <IonIcon
                        icon={arrowBack}
                        style={{
                          transform: window.innerWidth < 600 ? 'rotate(270deg)' : 'rotate(180deg)',
                          fontSize: '20px',
                          color: '#717171',
                          background: 'white',
                          position: 'relative',
                          zIndex: 1,
                          marginLeft: 2,
                          marginTop: 2
                        }}
                      />
                    </div>

                    {/* New Plan Box */}
                    <div style={{
                      flex: 1,
                      padding: '16px',
                      border: '2px solid #FF385C',
                      borderRadius: '8px',
                      background: '#FFF8F6'
                    }}>
                      <IonText color="medium" style={{ 
                        fontSize: '12px',
                        display: 'block',
                        marginBottom: '8px'
                      }}>
                        New Plan
                      </IonText>
                      <div style={{ marginBottom: '8px' }}>
                        <IonText color="dark" style={{ fontWeight: '500' }}>
                          {selectedPackage.name} - {selectedPrice.name}
                        </IonText>
                      </div>
                      <IonText color="medium">
                        {getPricing(selectedPrice)}
                      </IonText>
                    </div>
                  </div>
                </div>

                <div style={{
                  position: 'fixed',
                  bottom: '0',
                  left: '0',
                  right: '0',
                  background: 'linear-gradient(to top, white 80%, rgba(255,255,255,0.8) 95%, rgba(255,255,255,0) 100%)',
                  padding: '24px 16px',
                  zIndex: 100,
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <div style={{ maxWidth: '600px', width: '100%' }}>
                    <IonButton
                      expand="block"
                      onClick={migrateCustomers}
                      disabled={isMigrating}
                      style={{
                        '--background': '#FF385C',
                        '--background-activated': '#E31C5F',
                        '--border-radius': '8px',
                        '--box-shadow': 'none',
                        height: '48px',
                        margin: 0,
                        fontWeight: '500'
                      }}
                    >
                      Confirm Migration
                    </IonButton>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : null}
      </IonContent>
    </IonModal>
  )
} 